import { captureException } from '@sentry/core'
import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  PartialWithFieldValue,
  Query,
  QueryDocumentSnapshot,
  SetOptions,
  UpdateData,
  WithFieldValue,
} from 'firebase/firestore'
import {
  addDoc,
  deleteDoc,
  getDoc,
  getDocs,
  runTransaction,
  setDoc,
  updateDoc,
} from 'firebase/firestore'

export type SetDocWithErrorsArgsMandatoryOptions = [
  Parameters<typeof setDocWithError>[0],
  Parameters<typeof setDocWithError>[1],
  NonNullable<Parameters<typeof setDocWithError>[2]>,
]

export type DeleteDocWithErrorsArgsMandatoryOptions = [
  Parameters<typeof deleteDocWithError>[0],
  Parameters<typeof deleteDocWithError>[1],
]

export async function getDocsWithError<D, T extends DocumentData>(
  queryOrRef: Query<D, T>,
  errorName?: string
) {
  try {
    // eslint-disable-next-line no-restricted-syntax
    return await getDocs<D, T>(queryOrRef)
  } catch (error) {
    throw FirestoreFetchFailedError.fromError(error as Error, errorName)
  }
}

export async function getDocWithError<D, T extends DocumentData>(
  ref: DocumentReference<D, T>,
  errorName?: string
) {
  try {
    // eslint-disable-next-line no-restricted-syntax
    return await getDoc<D, T>(ref)
  } catch (error) {
    throw FirestoreFetchFailedError.fromError(error as Error, errorName)
  }
}

export async function setDocWithError<D, T extends DocumentData>(
  ref: DocumentReference<D, T>,
  data: PartialWithFieldValue<D>,
  options: SetOptions & { errorName?: string } = {}
) {
  try {
    // eslint-disable-next-line no-restricted-syntax
    return await setDoc<D, T>(ref, data, options)
  } catch (error) {
    throw FirestoreWriteFailedError.fromError(error as Error, options.errorName)
  }
}

export async function addDocWithError<D, T extends DocumentData>(
  ref: CollectionReference<D, T>,
  data: WithFieldValue<D>,
  errorName?: string
) {
  try {
    // eslint-disable-next-line no-restricted-syntax
    return await addDoc<D, T>(ref, data)
  } catch (error) {
    throw FirestoreWriteFailedError.fromError(error as Error, errorName)
  }
}

export async function updateDocWithError<D, T extends DocumentData>(
  ref: DocumentReference<D, T>,
  data: UpdateData<T>,
  errorName?: string
) {
  try {
    // eslint-disable-next-line no-restricted-syntax
    return await updateDoc<D, T>(ref, data)
  } catch (error) {
    throw FirestoreWriteFailedError.fromError(error as Error, errorName)
  }
}

export async function deleteDocWithError<D, T extends DocumentData>(
  ref: DocumentReference<D, T>,
  errorName?: string
) {
  try {
    // eslint-disable-next-line no-restricted-syntax
    return await deleteDoc<D, T>(ref)
  } catch (error) {
    throw FirestoreDeleteFailedError.fromError(error as Error, errorName)
  }
}

type RunTransactionWithErrorArgs = [
  ...params: Parameters<typeof runTransaction>,
  errorName?: string,
]

export async function runTransactionWithError(
  ...args: RunTransactionWithErrorArgs
) {
  const errorName =
    args.length === 4 ? (args.pop() as string | undefined) : undefined
  try {
    return await runTransaction(...(args as Parameters<typeof runTransaction>))
  } catch (error) {
    throw FirestoreTransactionFailedError.fromError(error as Error, errorName)
  }
}

/**
 * Executes multiple Firestore queries when the number of items in `matchArray` exceeds
 * the Firestore limit for 'in' clauses, then combines the results.
 *
 * @param queryBuilder - Function that builds a query for a partition of the match array
 * @param matchArray - Array of values to match against
 * @param maxPartitionLength - Maximum number of elements allowed in a single query (Default 10 - This is supposed to be lower than the Firebase limit.)
 * @param errorName - Name for error reporting
 * @returns Combined results from all queries
 */
export async function getDocsWithPartitionedQuery<D, T extends DocumentData>({
  queryBuilder,
  matchArray,
  maxPartitionLength = 10,
  errorName,
}: {
  queryBuilder: (partition: Array<string | number | boolean>) => Query<D, T>
  matchArray: Array<string | number | boolean>
  /** This is supposed to be lower than the Firebase limit. */
  maxPartitionLength?: number
  errorName?: string
}): Promise<Array<QueryDocumentSnapshot<D, T>>> {
  if (!matchArray.length) return []

  const uniqueArray = Array.from(new Set(matchArray))

  if (uniqueArray.length <= maxPartitionLength) {
    const query = queryBuilder(uniqueArray)
    const snapshot = await getDocsWithError(query, errorName)
    return snapshot.docs
  }

  const parts: (typeof uniqueArray)[] = []
  for (let i = 0; i < uniqueArray.length; i += maxPartitionLength) {
    parts.push(uniqueArray.slice(i, i + maxPartitionLength))
  }

  const snapshots = await Promise.all(
    parts.map(async (part) => {
      const query = queryBuilder(part)
      const snapshot = await getDocsWithError(query, errorName)
      return snapshot.docs
    })
  )

  return snapshots.flat()
}

export class FirestoreFetchFailedError extends Error {
  static captureFromError(error: Error, name: string) {
    const err = FirestoreFetchFailedError.fromError(error, name)
    captureException(err)
  }

  static fromError(error: Error, name?: string) {
    return new FirestoreFetchFailedError(error, name)
  }

  constructor(
    originalError: Error,
    name: string = 'FirestoreFetchFailedError'
  ) {
    const message = `${originalError.name}: ${originalError.message}`
    super(message)
    this.name = name
  }
}

export class FirestoreWriteFailedError extends Error {
  static captureFromError(error: Error, name: string) {
    const err = FirestoreWriteFailedError.fromError(error, name)
    captureException(err)
  }

  static fromError(error: Error, name?: string) {
    return new FirestoreWriteFailedError(error, name)
  }

  constructor(
    originalError: Error,
    name: string = 'FirestoreWriteFailedError'
  ) {
    const message = `${originalError.name}: ${originalError.message}`
    super(message)
    this.name = name
  }
}

export class FirestoreDeleteFailedError extends Error {
  static captureFromError(error: Error, name: string) {
    const err = FirestoreDeleteFailedError.fromError(error, name)
    captureException(err)
  }

  static fromError(error: Error, name?: string) {
    return new FirestoreDeleteFailedError(error, name)
  }

  constructor(
    originalError: Error,
    name: string = 'FirestoreWriteFailedError'
  ) {
    const message = `${originalError.name}: ${originalError.message}`
    super(message)
    this.name = name
  }
}

export class FirestoreTransactionFailedError extends Error {
  static captureFromError(error: Error, name: string) {
    const err = FirestoreTransactionFailedError.fromError(error, name)
    captureException(err)
  }

  static fromError(error: Error, name?: string) {
    return new FirestoreTransactionFailedError(error, name)
  }

  constructor(
    originalError: Error,
    name: string = 'FirestoreTransactionFailedError'
  ) {
    const message = `${originalError.name}: ${originalError.message}`
    super(message)
    this.name = name
  }
}
