import { makeObservable, observable, computed, action } from 'mobx'
import { Cubit } from './core'
import { RoomStateStatus, RoomState } from '../models/RoomState'
import type { FirestoreSectionAssignment } from '../firestore/SectionAssignment'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { getDefaultNameForRoom } from '../sources-of-truth/roomState'

export class NewRoomStateCubit extends Cubit {
  repository: FirebaseRepository

  @observable dayFilter: 'all' | 'm' | 't' | 'w' | 'th' | 'f' | 's' | 'su' =
    'all'

  @observable timeFilter: 'all' | 'morning' | 'afternoon' | 'evening' = 'all'

  @observable searchString: string = ''

  @observable roomIdToJoin: string | null = null

  constructor(repository: FirebaseRepository) {
    super()
    this.repository = repository
    makeObservable(this)
  }

  @action
  setDayFilter(d: typeof this.dayFilter) {
    this.dayFilter = d
  }

  @action
  setTimeFilter(t: typeof this.timeFilter) {
    this.timeFilter = t
  }

  @action
  setSearchString(s: typeof this.searchString) {
    this.searchString = s
  }

  @action
  setRoomIdToJoin(id: typeof this.roomIdToJoin) {
    this.roomIdToJoin = id
  }

  filterAndSortRooms({
    rooms,
    t,
    ...isRoomLockedArgs
  }: Omit<Parameters<typeof this.isRoomLocked>[0], 'room'> & {
    rooms: RoomState[]
    t: Parameters<typeof getDefaultNameForRoom>[1]
  }) {
    // needed to sort by number in virtual name
    const roomNameSortingCollator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base',
    })

    const roomsMap = new Map(rooms.map((room) => [room.id, room]))

    const roomsFilteredAndSorted = rooms
      .map((room) => {
        // remove current room from the list of rooms to get a unique name
        const roomsWithoutCurrentRoom = new Map(roomsMap)
        roomsWithoutCurrentRoom.delete(room.id)

        return {
          room,
          virtualName: getDefaultNameForRoom(
            Array.from(roomsWithoutCurrentRoom.values()),
            t
          ),
        }
      })
      .filter(
        ({ room }) =>
          !room.isFlaggedForDeletion &&
          this.roomMatchesSearchFilter(room) &&
          this.roomMatchesTimeFilter(room) &&
          this.roomMatchesDayFilter(room)
      )
      .sort((a, b) => {
        // todo: verify with kareem, disable non-empty priority until then
        // empty groups should always be sorted last
        // const aUsers = a.room.userIds.length
        // const bUsers = b.room.userIds.length
        // if ((!aUsers || !bUsers) && aUsers !== bUsers) return bUsers - aUsers

        const aTime = a.room.data.scheduledAt?.getTime()
        const bTime = b.room.data.scheduledAt?.getTime()

        // if one room is scheduled and other not, put scheduled room first
        if (aTime && !bTime) return -1
        if (!aTime && bTime) return 1
        if (aTime && bTime && aTime !== bTime) return aTime - bTime

        // if can't compare times, compare group name
        const aName = a.room.data.roomStateName || a.virtualName
        const bName = b.room.data.roomStateName || b.virtualName
        return roomNameSortingCollator.compare(aName, bName)
      })

    const { lockedRooms, unlockedRooms } = roomsFilteredAndSorted.reduce<{
      lockedRooms: typeof roomsFilteredAndSorted
      unlockedRooms: typeof roomsFilteredAndSorted
    }>(
      (acc, r) => {
        if (this.isRoomLocked({ room: r.room, ...isRoomLockedArgs }))
          acc.lockedRooms.push(r)
        else acc.unlockedRooms.push(r)
        return acc
      },
      {
        lockedRooms: [],
        unlockedRooms: [],
      }
    )

    // always add the phantom room as we want to use its virtual name for new group creation
    // we decide whether we want to display it or not from the client
    const phantomRoom = RoomState.empty(this.repository)
    const roomWithVirtualName = {
      room: phantomRoom,
      virtualName: getDefaultNameForRoom(rooms, t),
    }
    unlockedRooms.push(roomWithVirtualName)

    return { lockedRooms, unlockedRooms }
  }

  @computed
  get anyActiveFilters() {
    return (
      this.dayFilter !== 'all' ||
      this.timeFilter !== 'all' ||
      this.searchString.trim().length > 0
    )
  }

  private isRoomLocked({
    room,
    groupingSizeMaximum,
    roomStateStatus,
  }: {
    room: RoomState
    groupingSizeMaximum: FirestoreSectionAssignment['groupingSizeMaximum']
    roomStateStatus: RoomStateStatus
  }) {
    return (
      room.hasMaxUsers(groupingSizeMaximum) ||
      [
        RoomStateStatus.expired,
        RoomStateStatus.completed,
        RoomStateStatus.abandoned,
      ].includes(roomStateStatus)
    )
  }

  private roomMatchesSearchFilter(room: RoomState) {
    if (!this.searchString) return true
    const searchTerm = this.searchString.toLowerCase().trim()
    const userNamesWithRoomName =
      room.users.map((user) => user.fullName).join(' ') +
      (room.data.roomStateName || '')
    return userNamesWithRoomName.toLowerCase().includes(searchTerm)
  }

  private roomMatchesTimeFilter(room: RoomState) {
    // all = no filter
    if (this.timeFilter === 'all') return true

    if (!room.data.scheduledAt) return false

    const hour = room.data.scheduledAt.getHours()

    // morning midnight to 11am
    if (this.timeFilter === 'morning') return hour < 11

    // afternoon 11am to 5pm
    if (this.timeFilter === 'afternoon') return hour >= 11 && hour < 17

    // evening 5pm to midnight
    if (this.timeFilter === 'evening') return hour >= 17
    return false
  }

  private roomMatchesDayFilter(room: RoomState) {
    // all = no filter
    if (this.dayFilter === 'all') return true

    if (!room.data.scheduledAt) return false

    const day = room.data.scheduledAt.getDay()

    const dayToNumber = {
      m: 1,
      t: 2,
      w: 3,
      th: 4,
      f: 5,
      s: 6,
      su: 0,
    }

    return day === dayToNumber[this.dayFilter]
  }
}
