import type { Firestore } from 'firebase/firestore'
import type { ObservableMap } from 'mobx'
import { action, computed, makeObservable, observable } from 'mobx'
import { FirestoreRoomMessageType, type ChatMessage } from '../../types'
import {
  getRoomMessages,
  markMessageAsSeen,
  sendMessage,
} from '../../firestore/RoomMessage'
import type { StaticModelCollection } from '../../firestore-mobx/model'
import { RoomMessage } from '../../models/RoomMessage'
import { Cubit } from '../core'
import { type FirebaseRepository } from '../../models/FirebaseRepository'
import type { SystemMessage } from '../../firestore/RoomMessage'
import { captureException } from '@sentry/core'

function hasLocalStorage() {
  try {
    if (window.localStorage) return true
    if (global.localStorage) return true
    return false
  } catch (e) {
    return false
  }
}

export class ChatController extends Cubit {
  repository: FirebaseRepository
  firestore: Firestore
  roomId: string
  readMessages: ObservableMap<string, boolean> = observable.map({})
  messagesCol: StaticModelCollection<RoomMessage>

  constructor({
    repository,
    roomId,
  }: {
    repository: FirebaseRepository
    roomId: string
  }) {
    super()
    this.repository = repository
    this.firestore = repository.firestore
    this.roomId = roomId

    this.messagesCol = RoomMessage.emptyCollection(repository)
    this.restoreReadMessages()

    makeObservable(this, {
      isLoading: computed,
      hasData: computed,
      messages: computed,
      hasUnreadMessages: computed,
      unreadMessageCount: computed,
      markAllMessagesRead: action,
    })
  }

  initialize(): void {
    this.addStream(
      getRoomMessages(this.repository, { roomId: this.roomId }),
      (messages) => {
        this.messagesCol.replaceModels(messages)
      }
    )
  }

  get hasData() {
    return this.messagesCol.hasDocuments
  }

  get isLoading() {
    return this.messagesCol.isLoading
  }

  get unreadMessageCount() {
    let count = 0
    for (const message of this.messagesCol.documents) {
      if (!this.readMessages.get(message.id)) {
        count++
      }
    }
    return count
  }

  get hasUnreadMessages() {
    for (const message of this.messagesCol.documents) {
      if (!this.readMessages.get(message.id)) {
        return true
      }
    }
    return false
  }

  get messages() {
    if (this.isLoading) return []
    const messages: ChatMessage[] = []
    for (const message of this.messagesCol.models) {
      const author = message.author

      const data = message.data
      const isCurrentUserMessage = data.authorId === this.currentUser.uid

      const timestamp = data.createdAt

      const isSystemMessage = this.isSystemMessage(message)

      const systemMessagePayload = isSystemMessage
        ? this.parseSystemMessage(message)
        : undefined

      if (systemMessagePayload instanceof Error) {
        captureException(systemMessagePayload)
        continue
      }

      messages.push({
        ...data,
        text: data.text,
        id: message.id,
        author,
        firstFromUser: false,
        showAvatar: false,
        timestamp,
        isCurrentUserMessage,
        seenBy: data.seenBy,
        metadata: data.metadata,
        isSystemMessage,
        systemMessagePayload,
      })
    }
    const sorted = messages.sort((a, b) => {
      const aTime = a.createdAt?.getTime() || 0
      const bTime = b.createdAt?.getTime() || 0

      return aTime > bTime ? 1 : -1
    })

    let firstFromUser = true
    let lastUid = ''
    sorted.forEach((message) => {
      firstFromUser = lastUid !== message.authorId
      lastUid = message.authorId

      message.showAvatar =
        firstFromUser &&
        !message.isCurrentUserMessage &&
        message.author.data.imageUrl !== null

      message.firstFromUser = firstFromUser
    })

    return sorted
  }

  protected get currentUser() {
    if (!this.repository.currentUser) throw new Error('no current user')
    return this.repository.currentUser
  }

  protected isSystemMessage = (message: RoomMessage) =>
    message.data.type === FirestoreRoomMessageType.SchedulingBot &&
    !!message.data.metadata

  protected parseSystemMessage = (
    message: RoomMessage
  ): (SystemMessage & { id: string }) | Error => {
    if (!this.isSystemMessage(message) || !message.data.metadata)
      return new Error('Not a system message')
    return {
      id: message.id,
      ...message.data.metadata,
    }
  }

  sendMessage = async (text: string) => {
    sendMessage(this.firestore, this.roomId, this.currentUser.uid, text)
  }

  markAllMessagesRead() {
    for (const message of this.messagesCol.models) {
      this.readMessages.set(message.id, true)
    }
    this.storeReadMessages()
  }

  storeReadMessages() {
    if (!hasLocalStorage()) return

    localStorage.setItem(
      this.localStorageKey(),
      JSON.stringify({
        // expire in 1h
        expires: Date.now() + 1000 * 60 * 60,
        ids: Array.from(this.readMessages.keys()),
      })
    )
  }

  restoreReadMessages() {
    if (!hasLocalStorage()) return

    const read = localStorage.getItem(this.localStorageKey())

    if (!read) return

    // attempt to restore read messages, if it fails, clear it
    try {
      const data = JSON.parse(read)

      if (Date.now() > data.expires) {
        localStorage.removeItem(this.localStorageKey())
        return
      }

      for (const id of data.ids) {
        this.readMessages.set(id, true)
      }
    } catch (e) {
      console.error('failed to restore read messages', e)
      localStorage.removeItem(this.localStorageKey())
    }
  }

  async markSeen(message: ChatMessage) {
    // don't mark read if we authored the message
    if (
      message.authorId === this.currentUser.uid ||
      this.currentUser.uid in (message.seenBy ?? {})
    ) {
      return
    }
    await markMessageAsSeen(this.repository, this.roomId, message.id)
  }

  localStorageKey() {
    return `chat-${this.roomId}-readMessages`
  }
}
