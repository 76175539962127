import { type RoomState } from '../models/RoomState'

/**
 * Returns default name for a room if that room name is not already taken.
 * The default name is based on the number of rooms with the same name.
 * @example
 * const { t } = useTranslation()
 * const name = getDefaultNameForRoom(['Group 1', 'Group 2', 'custom name'], t)
 *
 * // name = 'Group 3'
 */
export const getDefaultNameForRoom = (
  roomsOrNames: RoomState[] | string[],
  translator: (k: string, { number }: { number: number }) => string
) => {
  let defaultNameNumber = 1
  const roomNames = new Set<string>()

  const getDefaultName = () =>
    translator('student_assignment.group_number', {
      number: defaultNameNumber,
    })

  roomsOrNames.forEach((roomOrName) => {
    const roomName =
      typeof roomOrName === 'string'
        ? roomOrName
        : roomOrName.data.roomStateName
    if (roomName) roomNames.add(roomName)
  })

  while (roomNames.has(getDefaultName())) defaultNameNumber++

  return getDefaultName()
}
