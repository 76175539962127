import type { ObservableMap } from 'mobx'
import { observable } from 'mobx'
import type { SlideTimerController } from '../SlideTimerController'
import type { MeetingCubit } from '../../MeetingCubit'
import type { TranscriptMessage } from '../../../types'

export class PreviewSlideTimerController implements SlideTimerController {
  meeting: MeetingCubit
  _messages: ObservableMap<number, TranscriptMessage> = observable.map({})

  slideTimer: NodeJS.Timeout | null = null
  videoDidNotStartTimer: NodeJS.Timeout | null = null
  debugMode = false

  constructor(meeting: MeetingCubit, options: { debug?: boolean } = {}) {
    this.meeting = meeting
    this.debugMode = options.debug || false
  }

  dispose(): void {
    this.cancelSlideTimer()
    this.cancelVideoDidNotStartTimer()
  }

  cancelSlideTimer() {
    this.debugLog('preview: cancelSlideTimer')
  }

  cancelVideoDidNotStartTimer() {
    this.debugLog('preview: cancel video did not start timer')
  }

  startVideoDidNotStartTimer() {
    this.debugLog('preview: start video did not start timer')
  }

  resetSlideTimer() {
    this.debugLog('preview: reset slide timer')
  }

  calculateSlideTimer() {
    this.debugLog('preview: calculate slide time')
  }

  endTimer() {
    this.cancelSlideTimer()
    this.meeting.updateProgressBar(1)
  }

  debugLog(...args: unknown[]) {
    if (this.debugMode) {
      // eslint-disable-next-line no-console
      console.log(...args)
    }
  }
}
