import { action, computed, makeObservable, observable } from 'mobx'
import {
  createRoomStateInspectorRequest,
  getRoomStateInspectorData,
} from '../firestore/RoomStateInspector'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { RoomStateInspector } from '../models/RoomStateInspector'
import { Cubit } from './core'
import { getDownloadURL, ref } from 'firebase/storage'

type NormalizedRoomState = {
  id: string
  userIds: string[]
  roomStateName: string
  slideDeckId: string
}

type NormalizedUser = {
  id: string
  firstName: string
  lastName: string
  imageUrl: string
  role: 'student' | 'instructor' | 'admin' | 'ta'
}

export type NormalizedSlide = {
  id: string
  slideName: string
  slideDescription: string
  slideOrder: number
  slideType: number
}

export type LogType = {
  event_name: string
  event_date: string
  user_id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event_params: Record<string, any>
  browser: string
  browser_version: string
}

export type EventType = {
  transcript: string
  eventTime: string
  userId: string
  eventType: number
  duration: number
  slideId?: string
}

export type ChatType = {
  authorId: string
  createdAt: string
  status: string
  text: string
  type: string
}

type FeedbackType = {
  score: number
  feedback: string
  comment: string
  userId: string
  flagMode: string
  reasons: string[]
}

type PayloadData = {
  roomState: NormalizedRoomState
  users: NormalizedUser[]
  slides: NormalizedSlide[]
  logs: LogType[]
  events: EventType[]
  chats: ChatType[]
  feedback: FeedbackType[]
}

export type EventLogGroup = {
  event_date: string
  user_id: string
  event_name: string
  events: LogType[]
}

type TimelineEntry =
  | {
      type: 'logGroup'
      item: EventLogGroup
      userId: string
      datetime: string
    }
  | {
      type: 'event'
      item: EventType
      userId: string
      datetime: string
    }
  | {
      type: 'chat'
      item: ChatType
      userId: string
      datetime: string
    }

export type QualityDataEntry = {
  datetime: number
  [userId: string]: number
}

export const EVENT_NAME_MAP: Record<number, string> = {
  0: 'Join Room',
  1: 'Leave Room',
  2: 'Transcript',
  3: 'Audio On',
  4: 'Audio Off',
  5: 'Video On',
  6: 'Video Off',
  7: 'Slide Change',
  8: 'Video Pause',
  9: 'Video Play',
  10: 'Video Seek',
  11: 'Video Stop',
  12: 'Recording Request',
  13: 'Recording Start',
  14: 'Recording Stop',
  15: 'Exhibit Show',
  16: 'Exhibit Hide',
  17: 'Pump Slide',
}

export const EVENT_TYPE_MAP = {
  join_room: 0,
  leave_room: 1,
  transcript: 2,
  audio_on: 3,
  audio_off: 4,
  video_on: 5,
  video_off: 6,
  slide_change: 7,
  video_pause: 8,
  video_play: 9,
  video_seek: 10,
  video_stop: 11,
  recording_request: 12,
  recording_start: 13,
  recording_stop: 14,
  exhibits_how: 15,
  exhibit_hide: 16,
  pump_slide: 17,
}

export const eventsKeysSortedByName = Object.keys(EVENT_NAME_MAP)
  .sort((a, b) =>
    EVENT_NAME_MAP[parseInt(a)].localeCompare(EVENT_NAME_MAP[parseInt(b)])
  )
  .map((key) => parseInt(key))

export class AdminInspectorCubit extends Cubit {
  repository: FirebaseRepository

  payload: RoomStateInspector

  @observable
  private _payloadData: PayloadData | null = null

  userLookup: Map<string, NormalizedUser> = observable.map<
    string,
    NormalizedUser
  >()

  userFilters = observable.map<string, boolean>([])
  logFilters = observable.map<string, boolean>([])
  eventFilters = observable.map<number, boolean>([])

  @observable
  showChat: boolean = true

  roomId: string

  @observable timelinePage = 1
  @observable timelinePageSize = 100

  constructor(repository: FirebaseRepository, roomId: string) {
    super()
    makeObservable(this)
    this.roomId = roomId
    this.payload = RoomStateInspector.empty(repository)
    this.repository = repository
  }

  initialize(): void {
    this.addRoomStatePayloadStream()
  }

  addRoomStatePayloadStream() {
    this.addStream(
      getRoomStateInspectorData(this.repository, { roomId: this.roomId }),
      (payload) => {
        // empty data happens on hot reload
        if (!payload.data) {
          this.payload.replaceData({
            state: 'empty',
          })
        }

        this.payload.replaceModel(payload)
      }
    )
  }

  requestRoomStatePayload() {
    return createRoomStateInspectorRequest(
      this.repository.firestore,
      this.roomId
    )
  }

  @computed
  get cloudStorageURI() {
    return this.payload.data?.cloudStorageURI
  }

  private _fetchingPayloadData = false
  fetchPayloadDataIfRequired() {
    if (!this.cloudStorageURI) return
    // if already fetched, return
    if (this._payloadData) return
    if (this._fetchingPayloadData) return
    if (this.roomId === 'sample') return

    const payloadRef = ref(this.repository.storage, this.cloudStorageURI)
    getDownloadURL(payloadRef)
      .then((url) => {
        fetch(url).then(async (res) => {
          const json = await res.json()

          this.resetPayloadData(json)
        })
      })
      .finally(() => {
        this._fetchingPayloadData = false
      })
  }

  @action
  changePageSize(size: number) {
    this.timelinePageSize = size
  }

  @action
  resetPayloadData(payloadData: PayloadData) {
    this._payloadData = payloadData
    for (const user of payloadData.users) {
      this.userLookup.set(user.id, user)
    }
    this.payload.replaceData({
      state: 'loaded',
    })
    this.setDefaultFilters()
  }

  @action setDefaultFilters() {
    this.addEventFilters(eventsKeysSortedByName)
    this.addLogFilters(this.allLogEventNames)
    for (const userId of this.userIds) {
      this.addUserFilter(userId)
    }
  }

  @computed
  get payloadData(): PayloadData | null {
    this.fetchPayloadDataIfRequired()

    return this._payloadData
  }

  @computed
  get userIds() {
    return this.payloadData?.roomState.userIds || []
  }

  @computed
  get allEvents() {
    return this.payloadData?.events || []
  }

  @computed
  get allSortedEvents() {
    const events = this.allEvents || []

    const sorted = events.slice().sort((a, b) => {
      return a.eventTime.localeCompare(b.eventTime)
    })

    return sorted
  }

  @computed
  get filteredEvents() {
    const events = this.allEvents

    // right now, all filters are on
    const filtered = events
      .filter((event) => this.eventFilters.get(event.eventType))
      .filter((event) => this.userFilters.get(event.userId) || !event.userId)

    return filtered
  }

  @computed
  get filteredSortedEvents() {
    const events = this.filteredEvents || []

    const sorted = events.slice().sort((a, b) => {
      return a.eventTime.localeCompare(b.eventTime)
    })

    return sorted
  }

  @computed
  get slides() {
    return this.payloadData?.slides || []
  }

  @computed
  get logs() {
    return this.payloadData?.logs || []
  }

  @computed
  get chats() {
    return this.payloadData?.chats || []
  }

  @computed
  get filteredLogs() {
    const logs = this.logs || []

    const filtered = logs.filter(
      (event) =>
        this.logFilters.get(event.event_name) &&
        this.userFilters.get(event.user_id)
    )

    return filtered
  }

  @computed
  get grouppedLogs() {
    const sortedLogs = this.filteredLogs.slice().sort((a, b) => {
      // sort by date and event name
      return (
        a.event_date.localeCompare(b.event_date) ||
        a.event_name.localeCompare(b.event_name)
      )
    })

    let last: EventLogGroup | null = null

    const groupedLogs: EventLogGroup[] = []

    for (const log of sortedLogs) {
      if (
        !last ||
        last.user_id !== log.user_id ||
        // last.event_date !== log.event_date ||
        last.event_name !== log.event_name
      ) {
        last = {
          event_date: log.event_date,
          user_id: log.user_id,
          event_name: log.event_name,
          events: [log],
        }

        groupedLogs.push(last)
      } else {
        last.events.push(log)
      }
    }

    return groupedLogs
  }

  @computed
  get allLogEventNames() {
    const logs = this.logs || []

    const names = new Set<string>()
    logs.forEach((event) => {
      names.add(event.event_name)
    })
    return Array.from(names).sort()
  }

  @computed
  get timelineEntries(): TimelineEntry[] {
    const logGroups = this.grouppedLogs
    const logEntries = logGroups.map((group) => {
      return {
        type: 'logGroup' as const,
        item: group,
        userId: group.user_id,
        datetime: group.event_date,
      }
    })

    const events = this.filteredEvents || []

    const eventEntries = events.map((event) => {
      return {
        type: 'event' as const,
        item: event,
        userId: event.userId || 'room',
        datetime: event.eventTime,
      }
    })

    const chats = this.showChat ? this.chats || [] : []

    const chatEntries = chats.map((chat) => {
      return {
        type: 'chat' as const,
        item: chat,
        userId: chat.authorId,
        datetime: chat.createdAt,
      }
    })

    const allEntries = [...logEntries, ...eventEntries, ...chatEntries]

    const sorted = allEntries.sort((a, b) => {
      const splitA = a.datetime.split('.')[0]
      const splitB = b.datetime.split('.')[0]
      return splitA.localeCompare(splitB)
    })

    return sorted
  }

  @computed
  get timelineEntriesPage() {
    return this.timelineEntries.slice(
      (this.timelinePage - 1) * this.timelinePageSize,
      this.timelinePage * this.timelinePageSize
    )
  }

  @computed
  get timelinePageCount() {
    return Math.ceil(this.timelineEntries.length / this.timelinePageSize)
  }

  @action setTimelinePage(page: number) {
    this.timelinePage = page
  }

  @action
  addLogFilters(filters: string[]) {
    filters.forEach((filter) => {
      this.logFilters.set(filter, true)
    })
  }

  @action
  resetLogFilters(filters: string[]) {
    this.logFilters.clear()
    filters.forEach((filter) => {
      this.logFilters.set(filter, true)
    })
  }

  @action
  addLogFilter(filter: string) {
    this.logFilters.set(filter, true)
  }

  @action
  removeLogFilter(filter: string) {
    this.logFilters.delete(filter)
  }

  @action
  addUserFilter(filter: string) {
    this.userFilters.set(filter, true)
  }

  @action
  removeUserFilter(filter: string) {
    this.userFilters.delete(filter)
  }

  @action
  resetUserFilters(filters: string[]) {
    this.userFilters.clear()
    filters.forEach((filter) => {
      this.userFilters.set(filter, true)
    })
  }

  @action
  addEventFilters(filters: number[]) {
    filters.forEach((filter) => {
      this.eventFilters.set(filter, true)
    })
  }

  @action
  resetEventFilters(filters: number[]) {
    this.eventFilters.clear()
    filters.forEach((filter) => {
      this.eventFilters.set(filter, true)
    })
  }

  @action
  addEventFilter(filter: number) {
    this.eventFilters.set(filter, true)
  }

  @action
  removeEventFilter(filter: number) {
    this.eventFilters.delete(filter)
  }

  @action
  setShowChat(showChat: boolean) {
    this.showChat = showChat
  }

  @computed
  get slidesById() {
    const slides = this.payloadData?.slides || []
    return new Map(slides.map((slide) => [slide.id, slide]))
  }

  @computed
  get connectionQualityLogs() {
    return this.logs
      .filter(
        (log) =>
          log.event_name === 'room_connection_quality_changed' ||
          log.event_name === 'room_connection_local_quality_changed'
      )
      .sort((a, b) => {
        return (
          new Date(a.event_date).getTime() - new Date(b.event_date).getTime()
        )
      })
  }

  @computed
  get firstTimestamp() {
    return this.logs?.reduce((acc, log) => {
      const timestamp = new Date(log.event_date).getTime()
      return Math.min(acc, timestamp)
    }, Infinity)
  }

  @computed
  get lastTimestamp() {
    return this.logs?.reduce((acc, log) => {
      const timestamp = new Date(log.event_date).getTime()
      return Math.max(acc, timestamp)
    }, 0)
  }

  @computed
  get firstQualityTimestamp() {
    return this.connectionQualityLogs?.reduce((acc, log) => {
      const timestamp = new Date(log.event_date).getTime()
      return Math.min(acc, timestamp)
    }, Infinity)
  }

  @computed
  get lastQualityTimestamp() {
    return this.connectionQualityLogs?.reduce((acc, log) => {
      const timestamp = new Date(log.event_date).getTime()
      return Math.max(acc, timestamp)
    }, 0)
  }

  @computed
  get allUserIds() {
    return [...new Set(this.logs.map((log) => log.user_id))]
  }

  @computed
  get allQualityUserIds() {
    return [...new Set(this.connectionQualityLogs.map((log) => log.user_id))]
  }

  @computed
  get connectionQualityData() {
    const allLogs = this.connectionQualityLogs

    // find the last event of each user, use it as the leave time
    const leaveTimes: Record<string, number> = {}
    const allLogsReversed = this.logs.slice().sort((a, b) => {
      return new Date(b.event_date).getTime() - new Date(a.event_date).getTime()
    })

    allLogsReversed.forEach((event) => {
      const userId = event.user_id
      if (!leaveTimes[userId]) {
        const leaveTime = Math.floor(
          new Date(event.event_date).getTime() / 1000
        )
        leaveTimes[userId] = leaveTime
      }
    })

    const datasByDate: Record<number, QualityDataEntry> = {}
    const lastQualityByUser: Record<string, number> = {}

    allLogs.forEach((log) => {
      const datetime = new Date(log.event_date).getTime() / 1000

      if (!datasByDate[datetime]) {
        datasByDate[datetime] = {
          datetime,
        }
      }

      const quality = qualityToNumber(
        (log.event_params.quality || '') as string
      )

      datasByDate[datetime][log.user_id] = quality

      const prev = lastQualityByUser[log.user_id]

      if (prev !== undefined) {
        const prevEntry = datasByDate[datetime - 1]
        if (prevEntry !== undefined) {
          // only add if the user is not already in the previous entry
          // that prevents overwriting the previous quality
          if (prevEntry[log.user_id] === undefined) {
            prevEntry[log.user_id] = prev
          }
        } else {
          datasByDate[datetime - 1] = {
            datetime: datetime - 1,
            [log.user_id]: prev,
          }
        }
      }

      lastQualityByUser[log.user_id] = quality
    })

    this.allQualityUserIds.forEach((userId) => {
      const leaveTime = leaveTimes[userId]
      if (!leaveTime) return
      const datum = datasByDate[leaveTime]
      const lastQuality = lastQualityByUser[userId]
      if (!lastQuality) return
      if (datum) {
        datum[userId] = lastQuality
      } else {
        // add a datum at the leave time
        datasByDate[leaveTime] = {
          datetime: leaveTime,
          [userId]: lastQuality,
        }
      }
    })

    const data = Object.values(datasByDate)

    return data
  }

  @computed
  get broadcastLogs(): LogType[] {
    if (!this.logs) return []

    return this.logs.filter((event) => event.event_name === 'broadcast_stats')
  }

  @computed
  get broadcastStats() {
    if (!this.logs) return null

    // output -> { user_id: { events: EventType[], video_packets_dropped_ratio: number, video_frames_dropped_ratio: number  } }
    // broadcastEvents

    const output: Record<
      string,
      {
        events: LogType[]
        video_packets_dropped_ratio: number
        video_frames_dropped_ratio: number
      }
    > = {}

    this.broadcastLogs.forEach((event) => {
      const userId = event.user_id
      if (!output[userId]) {
        output[userId] = {
          events: [],
          video_packets_dropped_ratio: 0,
          video_frames_dropped_ratio: 0,
        }
      }

      output[userId].events.push(event)
    })

    // now we have all the events for each user
    // we can calculate the ratios
    const userIds = Object.keys(output)

    for (const userId of userIds) {
      const userEvents = output[userId].events

      const allVideoPacketsReceived = userEvents.reduce((acc, event) => {
        if (event.event_name === 'broadcast_stats') {
          return acc + event.event_params.video_packets_received
        }

        return acc
      }, 0)

      const allVideoPacketsDropped = userEvents.reduce((acc, event) => {
        if (event.event_name === 'broadcast_stats') {
          return acc + event.event_params.video_packets_dropped
        }

        return acc
      }, 0)

      const allVideoFramesReceived = userEvents.reduce((acc, event) => {
        if (event.event_name === 'broadcast_stats') {
          return acc + event.event_params.video_frames_received
        }

        return acc
      }, 0)

      const allVideoFramesDropped = userEvents.reduce((acc, event) => {
        if (event.event_name === 'broadcast_stats') {
          return acc + event.event_params.video_frames_dropped
        }

        return acc
      }, 0)

      output[userId].video_packets_dropped_ratio =
        Math.floor((allVideoPacketsDropped / allVideoPacketsReceived) * 10000) /
        100
      output[userId].video_frames_dropped_ratio =
        Math.floor((allVideoFramesDropped / allVideoFramesReceived) * 10000) /
        100
    }

    return output
  }
}

export function qualityToNumber(quality: string) {
  switch (quality) {
    case 'excellent':
      return 3
    case 'good':
      return 2
    case 'poor':
      return 1
    default:
      return 0
  }
}

export function numberToQuality(number: number) {
  switch (number) {
    case 3:
      return 'excellent'
    case 2:
      return 'good'
    case 1:
      return 'poor'
    default:
      return 'lost'
  }
}
