// The dev server works a bit differently, so it produces a different URL for the worklet
// We also need to massage TS to allow the url imports

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - query params are not recognized by typescript
import MyWorkletProcessorUrlProd from './VolumeAnalyzerWorklet?worker&url'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - query params are not recognized by typescript
import MyWorkletProcessorUrl from './VolumeAnalyzerWorklet?url'

import type {
  AudioAnalyserOptions,
  LocalAudioTrack,
  LocalTrack,
  Track,
} from 'livekit-client'

// This is lifted from the livekit-client package to avoid importing livekit-client in the firebase-repository package
/**
 * @internal
 */
export function getNewAudioContext(): AudioContext | void {
  const AudioContext =
    typeof window !== 'undefined' &&
    // @ts-expect-error - vendor prefixes are not recognized by typescript
    (window.AudioContext || window.webkitAudioContext)
  if (AudioContext) {
    return new AudioContext({ latencyHint: 'interactive' })
  }
}

/**
 * Creates and returns an analyser web audio node that is attached to the provided track.
 * Additionally returns a convenience method `calculateVolume` to perform instant volume readings on that track.
 * Call the returned `cleanup` function to close the audioContext that has been created for the instance of this helper
 */
export function createAudioAnalyser(
  track: LocalTrack<Track.Kind.Audio>,
  options?: AudioAnalyserOptions
) {
  const opts = {
    cloneTrack: false,
    fftSize: 2048,
    smoothingTimeConstant: 0.8,
    minDecibels: -100,
    maxDecibels: -80,
    ...options,
  }
  const audioContext = getNewAudioContext()

  if (!audioContext) {
    throw new Error('Audio Context not supported on this browser')
  }
  const streamTrack = opts.cloneTrack
    ? track.mediaStreamTrack.clone()
    : track.mediaStreamTrack
  const mediaStreamSource = audioContext.createMediaStreamSource(
    new MediaStream([streamTrack])
  )
  const analyser = audioContext.createAnalyser()
  analyser.minDecibels = opts.minDecibels
  analyser.maxDecibels = opts.maxDecibels
  analyser.fftSize = opts.fftSize
  analyser.smoothingTimeConstant = opts.smoothingTimeConstant

  mediaStreamSource.connect(analyser)
  const dataArray = new Uint8Array(analyser.frequencyBinCount)

  /**
   * Calculates the current volume of the track in the range from 0 to 1
   */
  const calculateVolume = () => {
    analyser.getByteFrequencyData(dataArray)
    let sum = 0
    for (const amplitude of dataArray) {
      sum += Math.pow(amplitude / 255, 2)
    }
    const volume = Math.sqrt(sum / dataArray.length)
    return volume
  }

  const cleanup = async () => {
    await audioContext.close()
    if (opts.cloneTrack) {
      streamTrack.stop()
    }
  }

  return { calculateVolume, analyser, cleanup }
}

/**
 * This is our version of the audio analyzer that uses an audio worklet
 * to process incoming frames of audio data, calculate its volume in dBFS,
 * and trigger a callback when the volume crosses a threshold.
 *
 * @param track - The audio track to analyze
 * @param threshold - The threshold in dBFS at which the callback should be triggered
 * @param onThresholdCross - The callback to trigger when the volume crosses the threshold
 * @returns A cleanup function that should be called when the analyzer is no longer needed
 */
export async function createAudioVolumeAnalyser({
  track,
  onThresholdCross,
  debug,
}: {
  track: LocalTrack<Track.Kind.Audio> | LocalAudioTrack
  onThresholdCross: (over: boolean) => void
  debug?: boolean
}) {
  const audioContext = getNewAudioContext()

  if (!audioContext) {
    throw new Error('Audio Context not supported on this browser')
  }

  if (!track) return () => {}

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const inHotReloadDev = import.meta.hot

  const workletUrl = inHotReloadDev
    ? MyWorkletProcessorUrl
    : MyWorkletProcessorUrlProd

  const bandpass = audioContext.createBiquadFilter()
  bandpass.type = 'bandpass'
  bandpass.frequency.value = 1000
  bandpass.Q.value = 1

  await audioContext.audioWorklet.addModule(workletUrl)

  const streamTrack = track.mediaStreamTrack.clone()
  const source = audioContext.createMediaStreamSource(
    new MediaStream([streamTrack])
  )

  const workletNode = new AudioWorkletNode(audioContext, 'bl-volume-analyzer')

  workletNode.port.onmessage = (event) => {
    onThresholdCross(event.data.isCrossed)
  }

  source.connect(bandpass).connect(workletNode)

  const interval = debug
    ? setInterval(() => {
        workletNode.port.postMessage({ type: 'debug' })
      }, 1000)
    : null

  const cleanup = async () => {
    await audioContext.close()
    streamTrack.stop()
    if (interval) clearInterval(interval)
  }

  return cleanup
}
