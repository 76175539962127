import { computed, makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreSectionAssignment,
} from '../firestore/SectionAssignment'
import { buildSlideDeckObservableModelDocument } from '../firestore/SlideDeck'
import type { FirebaseRepository } from './FirebaseRepository'
import { DateTime } from 'luxon'
import { AssignmentState, AssignmentStatus } from '../types'

export type ScalarsType = FirestoreSectionAssignment['assignmentGradingScalars']

export class SectionAssignment extends ObservableModelWithDecorators<FirestoreSectionAssignment> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSectionAssignment>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  @computed
  get sectionId() {
    return this.unsafeData?.sectionId ?? ''
  }

  get expiresAt() {
    return this.unsafeData?.expiresAt ?? undefined
  }

  get assignedAt() {
    return this.unsafeData?.assignedAt ?? undefined
  }

  get assignmentStatus(): AssignmentStatus {
    // milliseconds in a day
    const oneDay = 1000 * 60 * 60 * 24
    if (this.expiresAt && this.expiresAt.getTime() + oneDay > Date.now()) {
      return AssignmentStatus.pending
    }
    return AssignmentStatus.completed
  }

  get expiresAtDate() {
    return this.expiresAt ? DateTime.fromJSDate(this.expiresAt) : undefined
  }

  get assignedAtIsInFuture() {
    return this.assignedAt ? this.assignedAt.getTime() > Date.now() : false
  }

  get isCancelled() {
    return this.data.assignmentState === AssignmentState.canceled
  }

  get isDraft() {
    return this.data.assignmentState === AssignmentState.draft
  }

  get isActive() {
    return this.data.assignmentState === AssignmentState.active
  }

  @computed
  get formattedAssignedAt() {
    // EX. Oct 18, 2024
    return this.assignedAt
      ? DateTime.fromJSDate(this.assignedAt).toFormat('LLL dd, yyyy')
      : ''
  }

  @computed
  get formattedAssignedAtWithTime() {
    // EX. Oct 18, 2024 12:00 PM
    return this.assignedAt
      ? DateTime.fromJSDate(this.assignedAt).toFormat('LLL dd, yyyy h:mm a')
      : ''
  }

  @computed
  get formattedExpiresAt() {
    // EX. Oct 18, 2024
    return this.expiresAt
      ? DateTime.fromJSDate(this.expiresAt).toFormat('LLL dd, yyyy')
      : ''
  }

  @computed
  get formattedExpiresAtWithTime() {
    // EX. Oct 18, 2024 12:00 PM
    return this.expiresAt
      ? DateTime.fromJSDate(this.expiresAt).toFormat('LLL dd, yyyy hh:mm a')
      : ''
  }

  @computed
  get isExpired() {
    return this.expiresAt
      ? this.expiresAt.getTime() < this.repository.currentMinute.toMillis()
      : false
  }

  @computed
  get slideDeck() {
    return buildSlideDeckObservableModelDocument(
      this.repository,
      this.data?.slideDeckId
    )?.model
  }

  @computed
  get assignmentType(): AssignmentType | undefined {
    if (!this.hasData) return undefined
    // return index of the rubric type enum
    switch (this.data.assignmentType) {
      case 0:
        return AssignmentType.ManyGroups
      case 1:
        return AssignmentType.OneGroup
      default:
        return undefined
    }
  }

  @computed
  get groupingType(): AssignmentGroupingType | undefined {
    if (!this.hasData) return undefined
    // return index of the rubric type enum
    switch (this.data.groupingType) {
      case 0:
        return AssignmentGroupingType.manual
      case 1:
        return AssignmentGroupingType.automaticRandom
      default:
        return undefined
    }
  }

  @computed
  get hasGrading() {
    return Boolean(this.unsafeData?.assignmentGradingScalars)
  }
}

export enum AssignmentType {
  ManyGroups, // Used to be called studentLed
  OneGroup, // Used to be called instructorLed
}

/// An enum that represents the type of assignment group.
export enum AssignmentGroupingType {
  /// manual
  manual,

  /// automaticRandom
  automaticRandom,
}
