import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import {
  fetchAllAppUsersByRole,
  fetchAllAppUsersStudents,
} from '../firestore/PublicUser'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import type { AppUser } from '../stores/AppUser'
import { Cubit } from './core'
import { createUserProfileToken } from '../firestore/UserProfileToken'
import { createInvitationInstructorWithCatalog } from '../firestore/Invitation'
import type { StaticModelCollection } from '../types'
import { InvitationType } from '../types'
import { Catalog } from '../models/Catalog'
import { getCatalogs } from '../firestore/Catalog'
import { UserProfileRole } from '../firestore/UserProfile/types'
import { fetchOrganizationsWithAdminAndInstructorIds } from '../firestore/Organization'
import { doc } from 'firebase/firestore'
import { updateDocWithError } from '../firestore-mobx/fetch'

export class AdminUsersCubit extends Cubit {
  repository: FirebaseRepository

  @observable
  role: UserProfileRole = UserProfileRole.instructor

  users = observable.map<string, AppUser[]>()
  students = observable.array<AppUser>()
  filters = observable.array<string>([])
  _catalogs: StaticModelCollection<Catalog>

  organizationsWithUserIds =
    observable.array<
      Awaited<
        ReturnType<typeof fetchOrganizationsWithAdminAndInstructorIds>
      >[number]
    >()

  @observable organizationsLoading = true
  @observable isLoading = false

  constructor(repository: FirebaseRepository) {
    super()
    makeObservable(this)

    this._catalogs = Catalog.emptyCollection(repository)
    this.repository = repository
  }

  initialize(): void {
    this.refetchUsers()
    this.fetchOrgsWithUserIds()
  }

  get catalogs() {
    if (this.hasStream('catalogs')) {
      return this._catalogs
    }

    this.addStream(
      getCatalogs(this.repository),
      (catalogs) => {
        this._catalogs.replaceModels(
          catalogs.sort((a, b) =>
            a.data.catalogName.localeCompare(b.data.catalogName)
          )
        )
      },
      {
        name: 'catalogs',
      }
    )

    return this._catalogs
  }

  @action
  async fetchOrgsWithUserIds() {
    const orgData = await fetchOrganizationsWithAdminAndInstructorIds(
      this.repository
    )
    this.organizationsWithUserIds.replace(orgData)
    this.organizationsLoading = false
  }

  async refetchUsers(): Promise<void> {
    if (this.role === UserProfileRole.student) return

    const role = this.role
    if (this.users.has(role)) return

    this.changeLoading(true)
    const users = await fetchAllAppUsersByRole(
      this.repository,
      role === UserProfileRole.admin
        ? [
            UserProfileRole.admin,
            UserProfileRole.author,
            UserProfileRole.editor,
            UserProfileRole.corre,
          ]
        : role
    )

    runInAction(() => {
      this.users.set(role, users)
    })

    this.changeLoading(false)
  }

  async refetchStudents(): Promise<void> {
    this.changeLoading(true)
    const filter = this.filters.join(' ').trim()
    runInAction(() => {
      this.students.replace([])
    })
    const students = await fetchAllAppUsersStudents(this.repository, filter)

    runInAction(() => {
      this.students.replace(students)
    })

    this.changeLoading(false)
  }

  @computed
  get userList() {
    // do not fetch students, they are only allowed to be fetched with filters

    if (this.role === UserProfileRole.student) return this.students

    const list = this.users.get(this.role) || []
    const filtered =
      this.filters.length > 0
        ? list.filter((user) => {
            const firstName = user.publicUser.data.firstName.toLowerCase()
            const lastName = user.publicUser.data.lastName.toLowerCase()
            const emailAddress =
              user.userProfile.data.emailAddress.toLowerCase()

            return this.filters.some((filter) => {
              const filterCased = filter.toLowerCase()
              return (
                firstName.includes(filterCased) ||
                lastName.includes(filterCased) ||
                emailAddress.includes(filterCased)
              )
            })
          })
        : list.concat()

    return filtered.sort((a, b) => {
      // Sort by first name then last name
      const aName = a.publicUser.data.firstName + a.publicUser.data.lastName
      const bName = b.publicUser.data.firstName + b.publicUser.data.lastName
      return aName.localeCompare(bName)
    })
  }

  @action
  setStudentFilter(filter: string) {
    this.filters.replace([filter])
    this.refetchStudents()
  }

  @action
  addFilter(filter: string) {
    this.filters.push(filter)
  }

  @action
  removeFilter = (filter: string) => {
    this.filters.remove(filter)
    this.students.clear()
  }

  @action
  changeRole(role: UserProfileRole) {
    this.role = role
    this.refetchUsers()
  }

  @action
  changeLoading(loading: boolean) {
    this.isLoading = loading
  }

  grantTokens(userId: string, tokenQuantity: number) {
    return createUserProfileToken(this.repository, {
      tokenQuantity: tokenQuantity,
      userId: userId,
    })
  }

  changeUserRole = async (userId: string, role: UserProfileRole) => {
    // Only admins can change roles.
    if (this.repository.breakoutUser?.role !== UserProfileRole.admin)
      throw new Error('Only admin can change roles')

    // Cannot change your own role.
    if (userId === this.repository.uid)
      throw new Error('Cannot change your own role')

    const ref = doc(this.repository.firestore, 'user_profile', userId)

    await updateDocWithError(ref, { role }, 'ChangeRoleError')
  }

  // Future<bool?> grantToken(String userId, int tokenQuantity) async {
  //   await createUserProfileToken(
  //     _firebaseRepository,
  //     tokenQuantity: tokenQuantity,
  //     userId: userId,
  //   );
  //   return true;
  // }

  async createInvitation({
    type,
    catalogId,
  }: {
    type: InvitationType
    catalogId: string
  }) {
    const doc = await createInvitationInstructorWithCatalog(this.repository, {
      catalogId: catalogId,
      oneTime: type === InvitationType.oneTime,
    })

    return doc.id
  }
}
