import type { SlideRubric } from '../models/SlideRubric'
import type { RoomStateRubricResult } from '../models/RoomStateRubricResult'

/**
 * Sorts rubrics by slide order, then alphabetically by rubric text
 *
 * @param rubrics - Array of SlideRubric objects to sort
 * @param slideIds - Optional array of slide IDs in desired order (empty string first for "All Slides")
 * @returns Sorted array of SlideRubric objects
 */
export function sortRubrics(rubrics: SlideRubric[]): SlideRubric[] {
  // Empty string is 'All Slides' option, must be first
  const orderedSlideIds = [
    '',
    ...new Set(rubrics.map((r) => r.data.slideId || '')),
  ]

  return [...rubrics].sort((a, b) => {
    const aIndex = orderedSlideIds.indexOf(a.data.slideId || '')
    const bIndex = orderedSlideIds.indexOf(b.data.slideId || '')

    if (aIndex === bIndex) {
      return a.data.rubric.localeCompare(b.data.rubric)
    }

    return aIndex - bIndex
  })
}

/**
 * Sorts rubric results based on the same ordering as the rubrics themselves
 *
 * @param rubricResults - Array of RoomStateRubricResult objects to sort
 * @param rubrics - Array of SlideRubric objects that define the ordering
 * @returns Sorted array of RoomStateRubricResult objects
 */
export function sortRubricResults(
  rubricResults: RoomStateRubricResult[],
  rubrics: SlideRubric[]
): RoomStateRubricResult[] {
  const rubricOrderMap = new Map<string, number>()

  sortRubrics(rubrics).forEach((rubric, index) => {
    rubricOrderMap.set(rubric.id, index)
  })

  return [...rubricResults].sort((a, b) => {
    const aOrder = rubricOrderMap.get(a.rubricId) ?? Number.MAX_SAFE_INTEGER
    const bOrder = rubricOrderMap.get(b.rubricId) ?? Number.MAX_SAFE_INTEGER
    return aOrder - bOrder
  })
}
