import { computed, makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  roomStateSetGroupLeaders,
  updateRoomState,
  type FirestoreRoomState,
} from '../firestore/RoomState'
import type { FirebaseRepository } from './FirebaseRepository'
import { DateTime } from 'luxon'
import { serverTimestamp } from 'firebase/firestore'
import { assert } from '../firestore-mobx/utils'
import { AssignmentState, RoomStateVideoMethod, SectionState } from '../types'
import { type FirestoreSectionAssignment } from '../firestore/SectionAssignment'

export const ROOM_CAN_START_MINUTES_BEFORE_SCHEDULED_AT = 15

export class RoomState extends ObservableModelWithDecorators<FirestoreRoomState> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  static maxAllowedUsers = 8

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreRoomState>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  @computed
  get users() {
    return this.repository.userStore.getUsers(this.data.userIds)
  }

  @computed
  get usersSortedByName() {
    return this.users
      .slice()
      .sort((a, b) => a.fullName.localeCompare(b.fullName))
  }

  @computed
  get groupLeaders() {
    return this.repository.userStore.getUsers(this.groupLeaderUserIds)
  }

  @computed
  get groupLeader() {
    return this.repository.userStore.getUser(this.groupLeaderUserId)
  }

  @computed
  get groupLeaderUserIds() {
    return this.data.groupLeaderUserIds
  }

  @computed
  get roomStateName(): string {
    if (!this.hasData) return ''
    if (this.data.roomStateName) return this.data.roomStateName
    return this.groupLeaders[0]?.fullName ?? ''
  }

  @computed
  get slideDeckId() {
    return this.hasData ? this.data.slideDeckId : ''
  }

  @computed
  get userIds() {
    return this.data.userIds
  }

  @computed
  get currentUserIsHidden(): boolean {
    return !!(
      this.data.hiddenUserIds &&
      this.data.hiddenUserIds.includes(this.repository.uid)
    )
  }

  @computed
  get userCount() {
    return this.userIds.length
  }

  @computed
  get groupLeaderUserId() {
    return this.groupLeaderUserIds[0]
  }

  @computed
  get didStart() {
    return (
      typeof this.data.activeSlide === 'number' && this.data.activeSlide >= 0
    )
  }

  @computed
  get isScheduled() {
    return (this.data.scheduledAt || null) !== null
  }

  hasMaxUsers(
    groupingSizeMaximum: FirestoreSectionAssignment['groupingSizeMaximum']
  ) {
    const max = groupingSizeMaximum ?? RoomState.maxAllowedUsers
    return this.userCount >= max
  }

  @computed
  get videoMethod() {
    if (this.data.videoMethod) {
      return this.data.videoMethod
    }
    // TODO: @alexshold we need to look at section value here
    if (this.repository.featureFlags.data.videoMethod) {
      return this.repository.featureFlags.data.videoMethod
    }
    return RoomStateVideoMethod.broadcast
  }

  @computed
  get isDemoCompleted() {
    assert(this.data.isDemo, 'isDemo must be true to invoke isDemoCompleted')
    const activeSlide = this.data.activeSlide
    const activeSlideChangedAt = this.data.activeSlideChangedAt || null
    const isFirstSlide = activeSlide === 0

    if (activeSlideChangedAt === null || !this.didStart) return false
    if (isFirstSlide && this.lastSlideChangeOver60MinutesAgo) return true
    if (!isFirstSlide && this.lastSlideChangeOver20MinutesAgo) return true
    return false
  }

  @computed
  get canStart() {
    const scheduledAt = this.data.scheduledAt
    if (!scheduledAt) return false
    return (
      this.repository.currentMinute >
      DateTime.fromJSDate(scheduledAt).minus({
        minutes: ROOM_CAN_START_MINUTES_BEFORE_SCHEDULED_AT,
      })
    )
  }

  get scheduledAtDate() {
    return this.data.scheduledAt
      ? DateTime.fromJSDate(this.data.scheduledAt)
      : undefined
  }

  get scheduledAtDateAsIsoShort() {
    if (!this.scheduledAtDate) return ''
    const meetingScheduledAtFormatted =
      this.scheduledAtDate.toISODate() +
      'T' +
      this.scheduledAtDate.toFormat('HH:mm')
    return meetingScheduledAtFormatted
  }

  getRoomStateStatus(
    expiresAt: Date | undefined,
    sectionState: SectionState,
    slideCount: number,
    assignmentState: AssignmentState,
    roomWasGraded: boolean | null
  ) {
    const assignmentExpired =
      expiresAt && expiresAt.getTime() < new Date().getTime()

    const assignmentCancelled = assignmentState === AssignmentState.canceled

    const expired = assignmentExpired || sectionState === SectionState.completed

    const roomDidStart = typeof this.data.activeSlide === 'number'

    const onLastSlide =
      typeof this.data.activeSlide === 'number' &&
      this.data.activeSlide >= slideCount - 1

    if (!roomDidStart) {
      if (assignmentCancelled) return RoomStateStatus.canceled
      if (expired) return RoomStateStatus.expired
      if (this.data.scheduledAt) return RoomStateStatus.scheduled
      return RoomStateStatus.mustSchedule
    }

    // room start and on last slide
    if (onLastSlide) return RoomStateStatus.completed

    // room start but not finished

    // if the assignment is cancelled or expired we look at graded as opposed to slide count to determine
    // if the room was completed, this is to avoid blocking users from reaching the last slide
    // if they still have access to the assignment
    // i.e reach 2nd to last slide, grading kicks off, they can no longer access the last slide
    if (assignmentCancelled || assignmentExpired) {
      if (roomWasGraded) return RoomStateStatus.completed
      return assignmentCancelled
        ? RoomStateStatus.canceled
        : RoomStateStatus.abandoned
    }

    // not cancelled or expired
    if (this.lastSlideChangeOver60MinutesAgo) return RoomStateStatus.suspended
    return RoomStateStatus.live
  }

  @computed
  get lastSlideChangeOver20MinutesAgo() {
    const activeSlideChangedAt = this.data.activeSlideChangedAt
    if (!activeSlideChangedAt) return false

    const delayCompleteByMinutes = 20

    const time20MinsAgo =
      activeSlideChangedAt.getTime() + 1000 * 60 * delayCompleteByMinutes

    return time20MinsAgo < this.repository.currentMinute.toMillis()
  }

  @computed
  get lastSlideChangeOver2MinutesAgo() {
    const activeSlideChangedAt = this.data.activeSlideChangedAt
    if (!activeSlideChangedAt) return false

    const delayCompleteByMinutes = 2

    const time20MinsAgo =
      activeSlideChangedAt.getTime() + 1000 * 60 * delayCompleteByMinutes

    return time20MinsAgo < this.repository.currentMinute.toMillis()
  }

  @computed
  get lastSlideChangeOver60MinutesAgo() {
    const activeSlideChangedAt = this.data.activeSlideChangedAt
    if (!activeSlideChangedAt) return false

    const delayCompleteByMinutes = 60

    const time60MinsAgo =
      activeSlideChangedAt.getTime() + 1000 * 60 * delayCompleteByMinutes

    return time60MinsAgo < this.repository.currentMinute.toMillis()
  }

  /**
   * the server should clean these up automatically, but we can filter them out
   * to avoid showing them to the user in the interim
   * */
  @computed
  get isFlaggedForDeletion() {
    return this.data.deletable && this.userCount === 0 && !this.didStart
  }

  startRoomIfNotStarted = async () => {
    const activeSlide = this.data.activeSlide
    if (activeSlide === null || activeSlide === undefined) {
      // if we are not a group leader, make ourselves a a group leader
      if (!this.data.groupLeaderUserIds.includes(this.repository.uid)) {
        await roomStateSetGroupLeaders(this.repository.firestore, this.id, [
          this.repository.uid,
        ])
      }
      // we are in a room that is not started, start it and become the leader
      await updateRoomState(this.repository.firestore, this.id, {
        activeSlide: 0,
        activeSlideChangedAt: serverTimestamp(),
        roomStartedAt: serverTimestamp(),
      })
    }
  }
}

/// define the room state status enum
export enum RoomStateStatus {
  /// live
  live = 0,

  /// completed
  completed = 1,

  /// abandoned
  abandoned = 2,

  /// scheduled
  scheduled = 3,

  /// mustSchedule
  mustSchedule = 4,

  /// expired
  expired = 5,

  /// suspended
  suspended = 6,

  /// canceled
  canceled = 7,
}
