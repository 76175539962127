import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx'
import { Cubit } from './core'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import type { StaticModelCollection } from '../firestore-mobx/model'
import { Section } from '../models/Section'
import { getSection, getSections } from '../firestore/Section'
import { RoomState } from '../models/RoomState'
import {
  getRoomStatesForStudent,
  getRoomWasGraded,
} from '../firestore/RoomState'
import { getSlideDeck } from '../firestore/SlideDeck'
import { SlideDeck } from '../models/SlideDeck'
import { SectionAssignment } from '../models/SectionAssignment'
import { AssignmentType } from '../models/SectionAssignment'
import { getSectionAssignments } from '../firestore/SectionAssignment'
import {
  ValidLibraryObject,
  ValidLibraryObjectActionState,
} from '../stores/ValidLibraryObject'
import { LibraryObjectState } from '../types'
import { RoomStateAnswer } from '../models/RoomStateAnswer'
import { SlideQuestion } from '../models/SlideQuestion'
import { fetchRoomStateAnswers } from '../firestore/RoomStateAnswer'
import { fetchSlideQuestions } from '../firestore/SlideQuestion'
import { getSettingsSectionPassPricing } from '../firestore/SettingsSectionPassPricing'
import { SettingsSectionPassPricing } from '../models/SettingsSectionPassPricing'

export class StudentLibraryCubit extends Cubit {
  repository: FirebaseRepository

  sections: StaticModelCollection<Section>
  roomStates: StaticModelCollection<RoomState>
  slideDecksById = observable.map<string, SlideDeck>()
  assignmentsBySectionId = observable.map<
    string,
    StaticModelCollection<SectionAssignment>
  >()

  userAnswersByRoomId = observable.map<
    string,
    StaticModelCollection<RoomStateAnswer>
  >()

  slideQuestionsBySlideDeckId = observable.map<
    string,
    StaticModelCollection<SlideQuestion>
  >()

  roomHasGradingResults = observable.map<string, boolean>()

  sectionPassPricing: SettingsSectionPassPricing

  // we keep track of what we've fetched so we don't fetch it again
  // we write here when we start the request so we don't start
  // it if its already in progress but not resolved
  private _fetchedAnswersForRoomId = new Set<string>()
  private _fetchedQuestionsForSlideDeckId = new Set<string>()

  @observable assignmentFilterSearchTerm: string = ''
  @observable assignmentFilter: StudentLibraryAssignmentFilter = 'current'
  @observable sortingStrategy: StudentLibraryAssignmentSortingStrategy =
    'default'

  @observable sortingOrder: StudentLibraryAssignmentSortingOrder = 'asc'

  // on first load if we want to wait for all userAnswers/slideQuestions to load to avoid flashing various states as they come in
  // afterwards if don't have the data yet we display the next eligible state
  libraryObjectForHeaderDidResolve = false

  // Backwards compatibility for old version of student library
  @observable showCompleted = false

  sectionId?: string

  constructor(
    repository: FirebaseRepository,
    {
      sectionId,
    }: {
      sectionId?: string
    } = {}
  ) {
    super()
    makeObservable(this)

    this.repository = repository

    this.sectionId = sectionId

    this.sections = Section.emptyCollection(repository)
    this.roomStates = RoomState.emptyCollection(repository)
    this.sectionPassPricing = SettingsSectionPassPricing.empty(repository)
  }

  initialize(): void {
    this.addStream(getSettingsSectionPassPricing(this.repository), (pricing) =>
      this.sectionPassPricing.replaceModel(
        pricing.hasData
          ? pricing
          : SettingsSectionPassPricing.empty(this.repository)
      )
    )

    if (this.sectionId) {
      this.addStream(
        getSection(this.repository, { sectionId: this.sectionId }),
        (section) => {
          this.sections.replaceModels([section])
          this.addSectionAssignmentStream(section.id)
        },
        {
          name: 'sections',
          onError: (error) => {
            console.error('Error getting sections', error)
            this.sections.replaceModels([])
          },
        }
      )
    } else {
      this.addStream(
        getSections(this.repository),
        (sections) => {
          this.sections.replaceModels(sections)

          for (const section of sections) {
            this.addSectionAssignmentStream(section.id)
          }
        },
        {
          name: 'sections',
          onError: (error) => {
            console.error('Error getting sections', error)
            this.sections.replaceModels([])
          },
        }
      )
    }

    this.addStream(
      getRoomStatesForStudent(this.repository, {
        sectionId: this.sectionId,
      }),
      (roomStates) => {
        this.roomStates.replaceModels(roomStates)
        this.startCheckRoomGradedStreamsForRooms(roomStates)
      },
      {
        name: 'room-states',
        onError: (error) => {
          console.error('Error getting room states', error)
          this.roomStates.replaceModels([])
        },
      }
    )

    this.addReaction({
      whenThisChanges: () => ({
        loaded: this.isLoaded,
        libraryObjects: this.libraryObjectsWhereQuizDataIsRequired,
      }),
      thenRunThisCode: ({ libraryObjects, loaded }) => {
        if (!loaded) return
        const roomIds = new Set<string>()
        const slideDeckIds = new Set<string>()
        libraryObjects.forEach((libObj) => {
          if (!libObj.quizDataRequiredForActionState) return
          roomIds.add(libObj.roomState.id)
          slideDeckIds.add(libObj.roomState.slideDeckId)
        })
        this.fetchQuizDataForRoomStatesAndSlideDecks(
          Array.from(roomIds),
          Array.from(slideDeckIds)
        )
      },
    })
  }

  addSectionAssignmentStream(sectionId: string): void {
    const key = `section-assignments-${sectionId}`
    this.addStream(
      getSectionAssignments(this.repository, {
        sectionId,
        onlyPublished: true,
      }),
      (assignments) => {
        const existing = this.assignmentsBySectionId.get(sectionId)
        if (existing) {
          existing.replaceModels(assignments)
        } else {
          const initial = SectionAssignment.emptyCollection(this.repository)
          initial.replaceModels(assignments)
          runInAction(() => {
            this.assignmentsBySectionId.set(sectionId, initial)
          })
        }

        for (const assignment of assignments) {
          this.addSlideDeckStream(assignment.data.slideDeckId)
        }
      },
      {
        name: key,
        onError: (error) => {
          console.error('Error getting assignments', error)
          this.roomStates.replaceModels([])
          // if it's an error, we want to set it to empty so it still counts as fetched
          const empty = SectionAssignment.emptyCollection(this.repository)
          runInAction(() => {
            this.assignmentsBySectionId.set(sectionId, empty)
          })
        },
      }
    )
  }

  addSlideDeckStream(slideDeckId: string): void {
    const slideDeckKey = `slide-deck-${slideDeckId}`
    // only add slide deck it once
    if (!this.hasStream(slideDeckKey)) {
      this.addStream(
        getSlideDeck(this.repository, { slideDeckId: slideDeckId }),
        (slideDeck) => {
          runInAction(() => {
            this.slideDecksById.set(slideDeck.id, slideDeck)
          })
        },
        {
          name: slideDeckKey,
          onError: (error) => {
            console.error(
              'Error getting slide deck for room state',
              slideDeckId,
              error
            )
            // if it's an error, we want to set it to empty so it still counts
            // and the page can render, even though this slide deck will be missing
            const empty = SlideDeck.empty(this.repository)
            runInAction(() => {
              this.slideDecksById.set(slideDeckId, empty)
            })
          },
        }
      )
    }
  }

  // Backwards compatibility for old version of student library
  @action
  toggleShowCompleted() {
    this.showCompleted = !this.showCompleted
  }

  @action quizDataForRoomState(roomState: RoomState): {
    slideQuestions: StaticModelCollection<SlideQuestion>
    userAnswers: StaticModelCollection<RoomStateAnswer>
  } {
    return {
      slideQuestions:
        this.slideQuestionsBySlideDeckId.get(roomState.slideDeckId) ||
        SlideQuestion.emptyCollection(this.repository),
      userAnswers:
        this.userAnswersByRoomId.get(roomState.id) ||
        RoomStateAnswer.emptyCollection(this.repository),
    }
  }

  @action
  /**
   * This function is called when the room states are loaded
   * only start streams for the rooms in which we need to know if they are graded
   */
  private startCheckRoomGradedStreamsForRooms(roomStates: RoomState[]) {
    roomStates.forEach(
      async ({
        id,
        data: { sectionId, activeSlide, assignmentId, slideDeckId },
      }) => {
        const roomGradingStreamKey = `room-grading-${id}`

        // if already streaming or
        if (this.hasStream(roomGradingStreamKey)) return

        // it not active slide or assignment, we don't need to check grading
        if (!sectionId || !activeSlide || !assignmentId || !slideDeckId) return //return this.roomHasGradingResults.set(id, false)

        // we need the assignment, so wait for it to load
        await when(
          () =>
            this.assignmentsBySectionId.has(sectionId) &&
            this.slideDecksById.has(slideDeckId)
        )

        const assignment = (
          this.assignmentsBySectionId.get(sectionId)?.models || []
        ).find((assignment) => assignment.id === assignmentId)

        const slideCount =
          this.slideDecksById.get(slideDeckId)?.data.slideDeckSlideCount

        const onLastSlide = slideCount && slideCount >= slideCount - 1

        // if assignment is not expired, or on last slide, we don't need to check grading
        // as we know the room is complete
        if (!assignment?.isExpired || onLastSlide) return

        // start streaming the grading results for this room
        this.addStream(
          getRoomWasGraded(this.repository, { roomStateId: id }),
          (graded) => {
            this.roomHasGradingResults.set(id, graded)
          }
        )
      }
    )
  }

  @action
  private fetchQuizDataForRoomStatesAndSlideDecks(
    roomIds: string[],
    slideDeckIds: string[]
  ) {
    const userId = this.repository.currentUser?.uid
    // i don't think we can reach this error, mostly here for type safety
    if (!userId) throw new Error('user must be logged in')

    const answerPromises: Promise<{
      answers: RoomStateAnswer[]
      roomId: string
    }>[] = []
    const questionPromises: Promise<{
      questions: SlideQuestion[]
      slideDeckId: string
    }>[] = []

    roomIds.forEach((roomId) => {
      if (this._fetchedAnswersForRoomId.has(roomId)) return
      this._fetchedAnswersForRoomId.add(roomId)
      answerPromises.push(
        (async () => {
          const answers = await fetchRoomStateAnswers(this.repository, {
            roomId,
            userId,
          })
          return { answers, roomId }
        })()
      )
    })

    slideDeckIds.forEach((slideDeckId) => {
      if (this._fetchedQuestionsForSlideDeckId.has(slideDeckId)) return
      this._fetchedQuestionsForSlideDeckId.add(slideDeckId)
      questionPromises.push(
        //fetchSlideQuestions(this.repository, { slideDeckId })
        (async () => {
          const questions = await fetchSlideQuestions(this.repository, {
            slideDeckId,
          })
          return { questions, slideDeckId }
        })()
      )
    })

    roomIds.forEach((roomId) => {
      if (this._fetchedAnswersForRoomId.has(roomId)) return
      this._fetchedAnswersForRoomId.add(roomId)
      answerPromises.push(
        (async () => {
          const answers = await fetchRoomStateAnswers(this.repository, {
            roomId,
            userId,
          })
          return { answers, roomId }
        })()
      )
    })

    Promise.all(answerPromises).then((resolvedAnswerRequests) => {
      resolvedAnswerRequests.forEach(({ answers, roomId }) => {
        const collection = RoomStateAnswer.emptyCollection(this.repository)
        collection.replaceModels(answers)
        runInAction(() => {
          this.userAnswersByRoomId.set(roomId, collection)
        })
      })
    })

    Promise.all(questionPromises).then((resolvedQuestionRequests) => {
      resolvedQuestionRequests.forEach(({ questions, slideDeckId }) => {
        const collection = SlideQuestion.emptyCollection(this.repository)
        collection.replaceModels(questions)

        runInAction(() => {
          this.slideQuestionsBySlideDeckId.set(slideDeckId, collection)
        })
      })
    })
  }

  @computed
  get isLoading() {
    return (
      this.sections.isLoading ||
      this.roomStates.isLoading ||
      this.assignmentsBySectionId.size !== this.sections.models.length ||
      this.sectionPassPricing.isLoading
    )
  }

  @computed
  get isLoaded() {
    return !this.isLoading && this.allSlideDecksLoaded
  }

  @computed
  get allSlideDecksLoaded() {
    // all assignments must be loaded to have all slide decks loaded
    if (this.assignmentsBySectionId.size !== this.sections.models.length)
      return false

    const slideDeckIds = Array.from(
      this.assignmentsBySectionId.values()
    ).flatMap((a) => a.models.map((a) => a.data.slideDeckId))

    return slideDeckIds.every((id) => this.slideDecksById.has(id))
  }

  @computed
  get allSectionAssignments() {
    const assignmentsArray = Array.from(this.assignmentsBySectionId.values())
    return assignmentsArray.flatMap((assignments) => assignments.models)
  }

  @computed
  get libraryObjects() {
    // do not compute until we have all data
    if (this.isLoading) return []
    const list: ValidLibraryObject[] = []
    for (const assignment of this.allSectionAssignments) {
      const roomStates = this.roomStates.models.filter(
        (roomState) => roomState.data.assignmentId === assignment.id
      )
      if (
        roomStates.length === 0 &&
        assignment.data.assignmentType === AssignmentType.ManyGroups
      ) {
        const libraryObj = new ValidLibraryObject({
          cubit: this,
          assignmentId: assignment.id,
          sectionId: assignment.data.sectionId,
        })
        list.push(libraryObj)
      } else {
        for (const roomState of roomStates) {
          const libraryObj = new ValidLibraryObject({
            cubit: this,
            assignmentId: assignment.id,
            sectionId: assignment.data.sectionId,
            roomId: roomState.id,
          })
          list.push(libraryObj)
        }
      }
    }
    return list
  }

  @computed get correctLibraryObjects() {
    return this.libraryObjects.filter(
      (libraryObject) => libraryObject.slideDeck.isNotEmpty
    )
  }

  @computed
  get libraryObjectForHeader() {
    if (this.isLoading) return null
    if (!this.libraryObjectForHeaderDidResolve) {
      // if fetches are in progress on the initial load we don't want to render a header yet
      const slideQuestionsLoading =
        this._fetchedQuestionsForSlideDeckId.size >
        this.slideQuestionsBySlideDeckId.size
      const userAnswersLoading =
        this._fetchedAnswersForRoomId.size > this.userAnswersByRoomId.size
      if (slideQuestionsLoading || userAnswersLoading) return null
    }

    const actionableLibraryObjects = this.correctLibraryObjects.filter(
      (libraryObj) =>
        actionTypeHasActionComponentToRender(libraryObj.actionState)
    )

    const sorted = actionableLibraryObjects.sort((a, b) => {
      const aActionState = a.actionState
      const bActionState = b.actionState
      const aPriority = priorityFromActionState(aActionState)
      const bPriority = priorityFromActionState(bActionState)

      // if no date provided use maximum possible date
      const aScheduledAt =
        a.roomState.data.scheduledAt || new Date(8640000000000000)
      const bScheduledAt =
        b.roomState.data.scheduledAt || new Date(8640000000000000)

      // if no date provided use max date (should never happen as only draft assignment can have this state)
      const aExpiresAt =
        a.assignment.data.expiresAt || new Date(8640000000000000)
      const bExpiresAt =
        b.assignment.data.expiresAt || new Date(8640000000000000)

      if (aPriority === bPriority) {
        // we only have priority collisions on on the following state
        // priority === 0 (join/start session)
        // priority === 3 (enroll/scheduleSession)

        // for start/join compare on scheduledTime
        if (aPriority === 0) return compareDates(aScheduledAt, bScheduledAt)
        // for enroll/scheduleSession compare on expiresAt
        if (aPriority === 3) return compareDates(aExpiresAt, bExpiresAt)

        // if we have a priority collision
        // and same dates maintain order
        return 0
      }

      // if priorities are different return the priority with the smaller number
      return aPriority - bPriority
    })

    // return the highest priority actionable object, or null if no actionable objects
    const libraryObjectToReturn = sorted.length ? sorted[0] : null
    if (!libraryObjectToReturn) return null
    this.libraryObjectForHeaderDidResolve = true
    return {
      libraryObject: libraryObjectToReturn,
      actionState: libraryObjectToReturn.actionState,
    }
  }

  @computed
  get sortedLibraryObjects() {
    const results = this.correctLibraryObjects
      .slice()
      .sort(sortingStrategyFromType(this.sortingStrategy))

    return this.sortingOrder === 'asc' ? results : results.reverse()
  }

  // Backwards compatibility for old version of student library
  @computed
  get filteredLibraryObjects() {
    if (!this.showCompleted) {
      return this.sortedLibraryObjects.filter((libraryObject) => {
        return (
          libraryObject.libraryObjectState !== LibraryObjectState.completed &&
          libraryObject.libraryObjectState !== LibraryObjectState.expired
        )
      })
    }
    return this.sortedLibraryObjects
  }

  @computed
  get filteredSortedLibraryObjects() {
    const strategyFilter = filterStrategyFromType(this.assignmentFilter)
    const filterWithSearchTerm = (libraryObject: ValidLibraryObject) => {
      if (this.assignmentFilterSearchTerm) {
        const {
          slideDeck: {
            data: { slideDeckName, slideDeckTeaser },
          },
          section: {
            data: { className, sectionName },
            instructor: { fullName: instructorName },
          },
        } = libraryObject

        const stringToSearch =
          `${slideDeckName} ${slideDeckTeaser} ${className} ${sectionName} ${instructorName}`.toLowerCase()

        if (
          !stringToSearch.includes(
            this.assignmentFilterSearchTerm.trim().toLowerCase()
          )
        ) {
          return false
        }
        return true
      }
      return strategyFilter(libraryObject)
    }
    return this.sortedLibraryObjects.filter(filterWithSearchTerm)
  }

  @computed
  get libraryObjectsWhereQuizDataIsRequired() {
    return this.libraryObjects.filter(
      (libraryObject) => libraryObject.quizDataRequiredForActionState
    )
  }

  @action
  changeAssignmentFilter(filter: StudentLibraryAssignmentFilter) {
    this.assignmentFilter = filter
  }

  @action
  changeSortingStrategy(
    strategy: StudentLibraryAssignmentSortingStrategy,
    order: StudentLibraryAssignmentSortingOrder
  ) {
    this.sortingStrategy = strategy
    this.sortingOrder = order
  }

  @action
  changeSearchTerm(searchTerm: string) {
    this.assignmentFilterSearchTerm = searchTerm
  }
}

function compareDates(a: Date | undefined, b: Date | undefined) {
  if (!a && !b) return 0
  if (!a) return 1
  if (!b) return -1
  return a.getTime() - b.getTime()
}

function isInPast(date: Date | undefined, now: Date) {
  if (!date) return false
  return date < now
}

function filterStrategyFromType(
  type: StudentLibraryAssignmentFilter
): (libraryObject: ValidLibraryObject) => boolean {
  const now = new Date()

  switch (type) {
    case 'all':
      return () => true
    case 'past':
      return (libraryObject) =>
        isInPast(libraryObject.assignment.data.expiresAt, now)
    case 'current':
      return (libraryObject) =>
        !isInPast(libraryObject.assignment.data.expiresAt, now) &&
        isInPast(libraryObject.assignment.data.assignedAt, now)
    case 'future':
      return (libraryObject) =>
        !isInPast(libraryObject.assignment.data.expiresAt, now) &&
        !isInPast(libraryObject.assignment.data.assignedAt, now)
  }
}

function sortingStrategyFromType(
  type: StudentLibraryAssignmentSortingStrategy
): (a: ValidLibraryObject, b: ValidLibraryObject) => number {
  switch (type) {
    case 'default':
      return (a, b) => {
        if (a.actionState === b.actionState) {
          if (a.libraryObjectState === LibraryObjectState.invited) {
            // sort expiresAt ascending
            return compareDates(
              a.assignment.data.expiresAt,
              b.assignment.data.expiresAt
            )
          } else if (a.libraryObjectState === LibraryObjectState.completed) {
            if (
              a.roomState.data.updatedAt === null ||
              b.roomState.data.updatedAt === null
            ) {
              // sort assignedAt descending
              return compareDates(
                b.assignment.data.assignedAt,
                a.assignment.data.assignedAt
              )
            }

            // sort updatedAt descending
            return compareDates(
              b.assignment.data.updatedAt,
              a.assignment.data.updatedAt
            )
          } else {
            // sort assignedAt descending
            return compareDates(
              b.assignment.data.assignedAt,
              a.assignment.data.assignedAt
            )
          }
        } else {
          const aActionStateOrder = getSortingPriorityFromActionState(
            a.actionState
          )
          const bActionStateOrder = getSortingPriorityFromActionState(
            b.actionState
          )
          return aActionStateOrder - bActionStateOrder
        }
      }
    case 'sectionName':
      return (a, b) => {
        const aClassName = a.section.data.className
        const aInstructorName = a.section.instructor.fullName

        const bClassName = b.section.data.className
        const bInstructorName = b.section.instructor.fullName

        const classComparison = aClassName.localeCompare(bClassName)
        const instructorComparison =
          aInstructorName.localeCompare(bInstructorName)
        if (classComparison === 0) return instructorComparison
        return classComparison
      }
    case 'experienceName':
      return (a, b) =>
        a.slideDeck.data.slideDeckName.localeCompare(
          b.slideDeck.data.slideDeckName
        )
    case 'expiresAt':
      return (a, b) => {
        return compareDates(
          a.assignment.data.expiresAt,
          b.assignment.data.expiresAt
        )
      }
    case 'scheduledAt':
      return (a, b) => {
        const aScheduled = a.roomState.scheduledAtDate
        const bScheduled = b.roomState.scheduledAtDate
        // if no scheduled put at end
        if (!aScheduled && !bScheduled) return 0
        if (!aScheduled) return 1
        if (!bScheduled) return -1
        return compareDates(aScheduled.toJSDate(), bScheduled.toJSDate())
      }
    case 'instructorName':
      return (a, b) => {
        const aInstructorName = a.section.instructor.fullName
        const bInstructorName = b.section.instructor.fullName
        return aInstructorName.localeCompare(bInstructorName)
      }
  }
}

function getSortingPriorityFromActionState(
  state: ValidLibraryObjectActionState
): number {
  switch (state) {
    case ValidLibraryObjectActionState.enroll:
      return 0
    case ValidLibraryObjectActionState.joinGroup:
      return 1
    case ValidLibraryObjectActionState.pending:
      return 2
    case ValidLibraryObjectActionState.sessionScheduled:
      return 3
    case ValidLibraryObjectActionState.scheduleSession:
      return 4
    case ValidLibraryObjectActionState.completeQuiz:
      return 5
    case ValidLibraryObjectActionState.joinSession:
    case ValidLibraryObjectActionState.startSession:
      return 6
    case ValidLibraryObjectActionState.suspended:
      return 7
    case ValidLibraryObjectActionState.loading:
      return 8
    case ValidLibraryObjectActionState.availableOn:
      return 9
    case ValidLibraryObjectActionState.viewResults:
      return 10
    case ValidLibraryObjectActionState.canceled:
    case ValidLibraryObjectActionState.experienceExpired:
      return 11
  }
}

export const studentLibraryAssignmentFilter = [
  'all',
  'future',
  'current',
  'past',
] as const
export type StudentLibraryAssignmentFilter =
  (typeof studentLibraryAssignmentFilter)[number]

export type StudentLibraryAssignmentSortingStrategy =
  | 'default'
  | 'sectionName'
  | 'experienceName'
  | 'expiresAt'
  | 'scheduledAt'
  | 'instructorName'

export type StudentLibraryAssignmentSortingOrder = 'asc' | 'desc'

/**
 * We have an action type for every LibraryObjectState
 * some have buttons for navigation and some do not
 *
 * we use a switch here for type safety if we add more states
 */
function actionTypeHasActionComponentToRender(
  actionState: ValidLibraryObjectActionState
): boolean {
  switch (actionState) {
    case ValidLibraryObjectActionState.enroll:
    case ValidLibraryObjectActionState.joinGroup:
    case ValidLibraryObjectActionState.scheduleSession:
    case ValidLibraryObjectActionState.completeQuiz:
    case ValidLibraryObjectActionState.joinSession:
    case ValidLibraryObjectActionState.startSession:
      return true

    // viewResults/suspended have action, but never show it in header
    case ValidLibraryObjectActionState.suspended:
    case ValidLibraryObjectActionState.viewResults:
    case ValidLibraryObjectActionState.availableOn:
    case ValidLibraryObjectActionState.sessionScheduled:
    case ValidLibraryObjectActionState.pending:
    case ValidLibraryObjectActionState.experienceExpired:
    case ValidLibraryObjectActionState.loading:
    case ValidLibraryObjectActionState.canceled:
      return false
  }
}

// returns number to be used for sorting
// where we return infinity we don't expect to have those in the filtered list
function priorityFromActionState(
  actionState: ValidLibraryObjectActionState
): number {
  switch (actionState) {
    case ValidLibraryObjectActionState.joinSession:
    case ValidLibraryObjectActionState.startSession:
      return 0

    case ValidLibraryObjectActionState.completeQuiz:
      return 1

    case ValidLibraryObjectActionState.joinGroup:
      return 2

    case ValidLibraryObjectActionState.scheduleSession:
    case ValidLibraryObjectActionState.enroll:
      return 3

    // viewResults/suspended have action, but never show it in header
    case ValidLibraryObjectActionState.suspended:
    case ValidLibraryObjectActionState.viewResults:
    case ValidLibraryObjectActionState.availableOn:
    case ValidLibraryObjectActionState.sessionScheduled:
    case ValidLibraryObjectActionState.pending:
    case ValidLibraryObjectActionState.experienceExpired:
    case ValidLibraryObjectActionState.loading:
    case ValidLibraryObjectActionState.canceled:
      return Number.POSITIVE_INFINITY
  }
}
