import { doc } from 'firebase/firestore'
import type { AssignmentGroupData } from '../cubits/InstructorAssignmentCubit'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import type { SlideRubric } from '../models/SlideRubric'
import { RoomStateRubricResult } from '../models/RoomStateRubricResult'

export function assignmentGroupWithRubricDefaults({
  assignmentGroupData,
  repository,
  slideRubrics = [],
}: {
  assignmentGroupData: AssignmentGroupData
  repository: FirebaseRepository
  slideRubrics?: SlideRubric[]
}): AssignmentGroupData {
  const hasAnyRubricResults = Array.from(
    assignmentGroupData.rubricResults.values()
  ).some((rubricMap) => rubricMap.size > 0)

  if (!hasAnyRubricResults) {
    return assignmentGroupData
  }

  const roomId = assignmentGroupData.roomState?.id || ''
  const activeUserIds = assignmentGroupData.users
    .filter((user) => !assignmentGroupData.absentUsers.includes(user))
    .map((user) => user.id)

  for (const userId of activeUserIds) {
    const userRubricResults =
      assignmentGroupData.rubricResults.get(userId) || new Map()

    for (const rubric of slideRubrics) {
      if (!userRubricResults.has(rubric)) {
        const defaultResult = new RoomStateRubricResult(repository, {
          id: rubric.id,
          data: {
            arguments: [],
            justification: 'Participant did not discuss.',
            roomId,
            score: 0,
            userId,
            assignmentId:
              assignmentGroupData.roomState?.data.assignmentId ?? '',
            sectionId: assignmentGroupData.roomState?.data.sectionId ?? '',
            updatedAt: new Date(),
          },
          ref: doc(repository.firestore, 'empty/empty'),
          fromCache: false,
          hasPendingWrites: false,
        })

        userRubricResults.set(rubric, [defaultResult])
      }
    }

    assignmentGroupData.rubricResults.set(userId, userRubricResults)
  }

  return assignmentGroupData
}
