import { computed, makeObservable, observable } from 'mobx'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { Cubit } from './core'
import type { NotificationGroup } from '../util/notifications'
import { groupNotifications } from '../util/notifications'
import type { StaticModelCollection } from '../types'
import { UserProfileNotification } from '../models/UserProfileNotification'
import {
  getUnseenUserProfileNotifications,
  markNotificationAsSeen,
  UserProfileNotificationType,
} from '../firestore/UserProfileNotification'
import type { Section } from '../models/Section'
import { fetchSection } from '../firestore/Section'
import { fetchSlideDeck } from '../firestore/SlideDeck'
import type { SlideDeck } from '../models/SlideDeck'

export class NotficationsCubit extends Cubit {
  repository: FirebaseRepository
  notifications: StaticModelCollection<UserProfileNotification>
  sections = observable.map<string, Section>()
  slideDecks = observable.map<string, SlideDeck>()

  constructor({ repository }: { repository: FirebaseRepository }) {
    super()
    makeObservable(this)
    this.repository = repository
    this.notifications = UserProfileNotification.emptyCollection(repository)
  }

  dispose() {
    this.clearSafeStartTimeout()
    return super.dispose()
  }

  @computed
  get grouped() {
    return groupNotifications(this.notifications.models)
  }

  @computed
  get unreadCount() {
    return this.grouped.length
  }

  private safeStartTimeout: NodeJS.Timeout | null = null

  safeStartNotificationsStream(): void {
    // if already installed, do nothing
    if (this.hasStream('notifications')) return

    const { isLoaded: flagsAreLoaded, flags } = this.repository.featureFlags
    const user = this.repository.breakoutUser

    // The conditions depend on these two being loaded
    // If they are not, defer the start of the stream
    const isReady = flagsAreLoaded && user?.isLoaded

    // wait for feature flags to load
    if (!isReady) {
      this.safeStartTimeout = setTimeout(
        () => this.safeStartNotificationsStream(),
        500
      )
      return
    }

    // don't start the notifications stream when feature flag is off
    if (!flags.showNotifications) {
      console.warn('Notifications feature flag is off, not starting stream')
      return
    }

    this.addStream(
      getUnseenUserProfileNotifications(this.repository, {
        userId: this.repository.uid,
        includeGroupChatNotifications: flags.showNewScheduling,
      }),
      (notifications) => {
        this.notifications.replaceModels(notifications)
        this.fetchRelatedData()
      },
      {
        name: 'notifications',
      }
    )
  }

  clearSafeStartTimeout() {
    if (this.safeStartTimeout) {
      clearTimeout(this.safeStartTimeout)
      this.safeStartTimeout = null
    }
  }

  fetchRelatedData() {
    this.fetchSections()
    this.fetchSlideDecks()
  }

  fetchSections() {
    const sectionIds = this.grouped
      .map((group) => group.context.sectionId)
      .filter(Boolean)
    if (sectionIds.length === 0) return
    const uniqueSectionIds = [...new Set(sectionIds)]

    const unfetchedSections = uniqueSectionIds.filter(
      (id) => this.sections.has(id) === false
    )

    if (unfetchedSections.length === 0) return

    for (const sectionId of unfetchedSections) {
      fetchSection(this.repository, { sectionId }).then((section) => {
        this.sections.set(section.id, section)
      })
    }
  }

  fetchSlideDecks() {
    const ids = this.grouped
      .map((group) => group.context.slideDeckId)
      .filter(Boolean)
    if (ids.length === 0) return
    const uniqueIds = [...new Set(ids)]

    const unfetchedIds = uniqueIds.filter(
      (id) => this.slideDecks.has(id) === false
    )

    if (unfetchedIds.length === 0) return

    for (const id of unfetchedIds) {
      fetchSlideDeck(this.repository, { slideDeckId: id }).then((model) => {
        this.slideDecks.set(model.id, model)
      })
    }
  }

  markGroupAsRead(group: NotificationGroup) {
    for (const notification of group.notifications) {
      markNotificationAsSeen(this.repository, {
        notificationId: notification.id,
      })
    }
  }

  markAllAsRead() {
    for (const group of this.grouped) {
      this.markGroupAsRead(group)
    }
  }

  markAssignmentAsRead(sectionId: string, assignmentId: string) {
    const group = this.grouped.find(
      (group) =>
        group.context.sectionId === sectionId &&
        group.context.assignmentId === assignmentId
    )
    if (group) {
      this.markGroupAsRead(group)
    }
  }

  markChatMessageAsRead(messageId: string) {
    const notification = this.notifications.models.find(
      (notification) =>
        notification.data.notificationContextKey.messageId === messageId
    )
    if (notification) {
      markNotificationAsSeen(this.repository, {
        notificationId: notification.id,
      })
    }
  }

  getChatNotificationsForAssignmentCount(
    sectionId: string,
    assignmentId: string
  ): number {
    return this.notifications.models.filter(
      (notification) =>
        notification.data.notificationType ===
          UserProfileNotificationType.GroupChat &&
        notification.data.notificationContextKey.sectionId === sectionId &&
        notification.data.notificationContextKey.assignmentId === assignmentId
    ).length
  }
}
