import { action, computed } from 'mobx'
import { getRoomState, updateRoomState } from '../firestore/RoomState'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { RoomState } from '../models/RoomState'
import { Cubit } from './core'
import { DateTime } from 'luxon'
import { PublicUser } from '../models/PublicUser'
import { RoomStateVideoMethod } from '../firestore/RoomState/types'

export class RoomStateCubit extends Cubit {
  roomId: string
  repository: FirebaseRepository
  roomState: RoomState

  constructor(repository: FirebaseRepository, roomId: string | undefined) {
    super()
    this.repository = repository
    this.roomId = roomId || ''
    this.roomState = RoomState.empty(repository)
  }

  initialize(): void {
    // we don't want to load the room state if the room id is not set
    // set loading to false and show NotFound page
    if (!this.roomId) return this.roomState.changeLoadingState(false)
    this.addStream(
      getRoomState(this.repository, { roomStateId: this.roomId }),
      (newRoomState) => {
        if (newRoomState.data === undefined) return

        this.roomState.replaceData(newRoomState.data)
      }
    )
  }

  @action
  ensureVideoMethod(expectedMethod: RoomStateVideoMethod) {
    if (this.roomState.data?.videoMethod === expectedMethod) {
      return Promise.resolve()
    }

    return updateRoomState(this.repository.firestore, this.roomId, {
      videoMethod: expectedMethod,
    })
  }

  @computed
  get roomIsStale() {
    const activeSlideChangedAt = this.roomState.data.activeSlideChangedAt
      ? DateTime.fromJSDate(this.roomState.data.activeSlideChangedAt)
      : null
    if (!activeSlideChangedAt) return false

    // Check if the last change slide is older than 30 minutes.
    const staleTime = 30 * 60 * 1000 // 30 minutes
    const roomIsStale =
      activeSlideChangedAt.plus({ milliseconds: staleTime }) < DateTime.now()

    return roomIsStale
  }

  @computed
  get groupLeaderUser() {
    const groupLeaderUserIds = this.roomState.data?.groupLeaderUserIds
    const id = groupLeaderUserIds[0]
    if (!id) return PublicUser.empty(this.repository)

    return this.repository.userStore.getUser(id)
  }

  // Ex. 2/21/24 - 12:00PM
  @computed
  get formattedDate() {
    if (!this.roomState.data?.scheduledAt) return ''
    const dateTime = DateTime.fromJSDate(this.roomState.data.scheduledAt)

    return dateTime.toFormat('M/d/yy - h:mma')
  }
}
