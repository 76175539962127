import {
  and,
  collection,
  doc,
  orderBy,
  query,
  where,
  type CollectionReference,
  type DocumentData,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { StreamInterface } from 'tricklejs/dist/types'
import { ZodError } from 'zod'
import { modelListStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { UserProfileNotification } from '../../models/UserProfileNotification'
import type { FirestoreUserProfileNotification } from './schema'
import { schema, UserProfileNotificationType } from './schema'
import { updateDocWithError } from '../../firestore-mobx/fetch'
import { DateTime } from 'luxon'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreUserProfileNotification> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    try {
      return schema.parse(data)
    } catch (e) {
      if (e instanceof ZodError) {
        console.error('Error parsing favorite slide deck', e.errors)
      }
      throw e
    }
  },
}

const getColRef = (
  firestore: Firestore,
  userId: string
): CollectionReference<FirestoreUserProfileNotification, DocumentData> => {
  return collection(
    firestore,
    'user_profile',
    userId,
    'notification'
  ).withConverter(converter)
}

export const markNotificationAsSeen = (
  repository: FirebaseRepository,
  params: { notificationId: string }
): Promise<void> => {
  const uid = repository.uid
  const ref = getColRef(repository.firestore, uid)
  const docRef = doc(ref, params.notificationId)

  return updateDocWithError(docRef, {
    notificationSeen: true,
  })
}

export const getUnseenUserProfileNotifications = (
  repository: FirebaseRepository,
  params: {
    userId: string
    includeGroupChatNotifications?: boolean
  }
): StreamInterface<UserProfileNotification[]> => {
  const ref = getColRef(repository.firestore, params.userId)

  const predicates = [
    where('notificationSeen', '==', false),
    where('createdAt', '>=', DateTime.now().minus({ days: 30 }).toJSDate()),
  ]

  if (params.includeGroupChatNotifications) {
    predicates.push(
      where('notificationType', 'in', [
        UserProfileNotificationType.Custom,
        UserProfileNotificationType.GroupChat,
      ])
    )
  } else {
    predicates.push(
      where('notificationType', 'in', [UserProfileNotificationType.Custom])
    )
  }

  const q = query(ref, and(...predicates), orderBy('createdAt'))

  return modelListStream(repository, q, UserProfileNotification)
}
