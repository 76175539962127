import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import type { FirebaseRepository } from '../models/FirebaseRepository'
import { Cubit } from './core'
import { Organization } from '../models/Organization'
import {
  addInstructorToOrganization,
  addOrganizationalAdminToOrganization,
  updateOrganizationState,
  getOrganization,
  removeInstructorFromOrganization,
  removeOrganizationalAdminFromOrganization,
  updateOrganization,
  getOrganizationAdmins,
  getOrganizationInstructors,
} from '../firestore/Organization'
import { type AppUser } from '../stores/AppUser'
import { fetchAllAppUsersByRole } from '../firestore/PublicUser'
import type {
  OrganizationInstructorDetails,
  OrganizationInvoiceStatus,
  StaticModelCollection,
} from '../types'
import {
  type OrganizationState,
  SectionRequestState,
  SectionRequestType,
  UserProfileRole,
} from '../types'
import { Catalog } from '../models/Catalog'
import {
  getCatalogs,
  getCatalogsByIds,
  getCatalogsForUser,
} from '../firestore/Catalog'
import { OrganizationCatalog } from '../models/OrganizationCatalog'
import { getOrganizationCatalogs } from '../firestore/OrganizationCatalog'
import { createOrgInvitation } from '../firestore/Invitation'
import { updateOrgInstructor } from '../firestore/OrganizationInstructor'
import type { FirestoreOrganizationInstructor } from '../firestore/OrganizationInstructor/schema'
import {
  addCatalogToUserProfile,
  removeCatalogFromUserProfile,
} from '../firestore/UserProfile'
import {
  getSectionsByOrganizationId,
  setSectionInvoiced,
} from '../firestore/Section'
import type { Section } from '../models/Section'
import { getSectionAssignments } from '../firestore/SectionAssignment'
import type { SectionAssignment } from '../models/SectionAssignment'
import { SectionRequest } from '../models/SectionRequest'
import {
  getAllSectionRequestsByOrganizationId,
  updateSectionRequestState,
} from '../firestore/SectionRequest'
import type { PublicUser } from '../models/PublicUser'
import {
  createOrUpdateOrganizationIntegration,
  getOrganizationIntegrations,
} from '../firestore/OrganizationIntegration'
import { OrganizationIntegration } from '../models/OrganizationIntegration'

export class OrganizationCubit extends Cubit {
  repository: FirebaseRepository
  organizationId: string
  organization: Organization

  catalogs: StaticModelCollection<Catalog>
  organizationCatalogs: StaticModelCollection<OrganizationCatalog>
  organizationIntegrations: StaticModelCollection<OrganizationIntegration>

  private _adminsForOrganization = observable.array<PublicUser>()
  private _instructorsForOrganization =
    observable.array<OrganizationInstructorDetails>()

  private _sectionsForOrganization = observable.array<Section>()
  private _assignmentsForOrganizationBySectionId = observable.map<
    string,
    SectionAssignment[]
  >()

  sectionRequestsForOrganization: StaticModelCollection<SectionRequest>

  @observable
  filterSectionRequestsBy: 'pending' | 'not_completed' = 'pending'

  @observable
  private didLoadAdminsForOrganization = false

  @observable
  private didLoadInstructorsForOrganization = false

  @observable
  private didFetchAllInstructors = false

  @observable
  private didLoadAllInstructors = false

  private allInstructors = observable.array<AppUser>()

  @observable
  selectedTab:
    | 'settings'
    | 'instructors'
    | 'classes'
    | 'invoice_requests'
    | 'integrations' = 'settings'

  constructor(repository: FirebaseRepository, organizationId: string) {
    super()
    makeObservable(this)
    this.repository = repository
    this.organization = Organization.empty(repository)
    this.organizationId = organizationId
    this.catalogs = Catalog.emptyCollection(repository)
    this.organizationCatalogs = OrganizationCatalog.emptyCollection(repository)
    this.organizationIntegrations =
      OrganizationIntegration.emptyCollection(repository)
    this.sectionRequestsForOrganization =
      SectionRequest.emptyCollection(repository)
  }

  initialize(): void {
    const isCorre = this.repository.breakoutUser?.isCorre || false
    if (isCorre) {
      // load all catalogs for admin users
      this.addStream(getCatalogs(this.repository), (catalogs) => {
        runInAction(() => {
          this.catalogs.replaceModels(
            catalogs.sort((a, b) =>
              a.data.catalogName.localeCompare(b.data.catalogName)
            )
          )
        })
      })
    }

    this.addStream(
      getOrganization(this.repository, { organizationId: this.organizationId }),
      (org) => {
        this.organization.replaceModel(org)
      }
    )
    this.addStream(
      getOrganizationCatalogs(this.repository, {
        organizationId: this.organizationId,
      }),
      (orgCatalogs) => {
        runInAction(() => {
          this.organizationCatalogs.replaceModels(orgCatalogs)
          // shallow ids copy is made and sorted to ensure that the order of the catalogIds is for sake of key checking
          const catalogIds = orgCatalogs.map(({ id }) => id).sort()
          const streamNamespace = 'catalogs'
          const streamName = `catalogs-${catalogIds.join('-')}`
          if (!isCorre && !this.hasStream(streamName)) {
            // cancel streams in namespace (if any)
            this.removeStreams(streamNamespace)

            this.addStream(
              getCatalogsByIds(this.repository, { catalogIds }),
              (catalogs) => {
                runInAction(() => {
                  this.catalogs.replaceModels(catalogs)
                })
              }
            ),
              {
                name: streamName,
                namespace: streamNamespace,
              }
          }
        })
      }
    )

    this.addStream(
      getOrganizationIntegrations(this.repository, this.organizationId),
      (integrations) => {
        runInAction(() => {
          this.organizationIntegrations.replaceModels(integrations)
        })
      }
    )

    this.addStream(
      getOrganizationAdmins(this.repository, {
        organizationId: this.organizationId,
      }),
      (admins) => {
        runInAction(() => {
          this.didLoadAdminsForOrganization = true
          const adminsSortedAlphabetically = admins.sort((a, b) =>
            a.fullName.localeCompare(b.fullName)
          )
          this._adminsForOrganization.replace(adminsSortedAlphabetically)
        })
      }
    )

    this.addStream(
      getOrganizationInstructors(this.repository, {
        organizationId: this.organizationId,
      }),
      (instructors) => {
        runInAction(() => {
          this.didLoadInstructorsForOrganization = true
          this._instructorsForOrganization.replace(instructors)
        })
      }
    )

    this.addSectionRequestStream()

    this.addStream(
      getSectionsByOrganizationId(this.repository, this.organizationId),
      (sections) => {
        this._sectionsForOrganization.replace(sections)

        this._sectionsForOrganization.forEach((section) => {
          this.addStream(
            getSectionAssignments(this.repository, {
              sectionId: section.id,
            }),
            (assignments) => {
              runInAction(() => {
                this._assignmentsForOrganizationBySectionId.set(
                  section.id,
                  assignments
                )
              })
            }
          )
        })
      }
    )
  }

  addMyselfAsInstructor = async () => {
    if (!this.repository.currentUser) return
    await addInstructorToOrganization(this.repository, {
      userId: this.repository.currentUser?.uid || '',
      organizationId: this.organizationId,
    })
  }

  @action
  setFilterSectionRequestsBy(filter: 'pending' | 'not_completed') {
    this.filterSectionRequestsBy = filter
  }

  @action
  updateSectionRequestsForOrganization = (requests: SectionRequest[]) => {
    this.sectionRequestsForOrganization.replaceModels(requests)
  }

  @computed
  get filteredSectionRequests() {
    if (this.filterSectionRequestsBy === 'pending') {
      return this.sectionRequestsForOrganization.models.filter(
        (request) => request.data.sectionRequestState === 0
      )
    }
    return this.sectionRequestsForOrganization.models.filter(
      (request) => request.data.sectionRequestState !== 0
    )
  }

  addSectionRequestStream() {
    this.addStream(
      getAllSectionRequestsByOrganizationId(
        this.repository,
        this.organizationId
      ),
      this.updateSectionRequestsForOrganization,
      {
        name: 'sectionRequests',
      }
    )
  }

  updateOrgInstructor = async (
    userId: string,
    payload: Pick<FirestoreOrganizationInstructor, 'autoApproveInvoice'>
  ): Promise<void> => {
    await updateOrgInstructor(
      this.repository,
      this.organizationId,
      userId,
      payload
    )
  }

  updateOrganization = async (params: {
    organizationName: string
    organizationCatalogIds: string[]
    organizationDefaultCatalogIds: string[]
    organizationInvoiceStatus: OrganizationInvoiceStatus
  }): Promise<void> => {
    await updateOrganization(this.repository, this.organizationId, {
      ...params,
      currentOrganizationCatalogIds: this.organizationCatalogs.models.map(
        ({ id }) => id
      ),
      currentOrganizationDefaultCatalogIds:
        this.organizationCatalogs.models.reduce<string[]>(
          (acc, { id, data: { defaultCatalogId } }) => {
            if (defaultCatalogId) {
              acc.push(id)
            }
            return acc
          },
          []
        ),
    })
  }

  getCatalogsForInstructor = (userId: string) => {
    return getCatalogsForUser(this.repository, {
      userId,
    })
  }

  updateOrganizationState = async (state: OrganizationState): Promise<void> => {
    await updateOrganizationState(this.repository, this.organizationId, state)
  }

  @computed
  get addableInstructors(): { loading: boolean; users: AppUser[] } {
    if (!this.didFetchAllInstructors) this.fetchInstructors()
    return {
      loading: !this.didLoadAllInstructors,
      // Filter out instructors that are already associated with the organization
      users: this.allInstructors.filter((instructor) => {
        return !this._instructorsForOrganization.find(
          (u) => u.publicUser.id === instructor.uid
        )
      }),
    }
  }

  @computed
  get adminsForOrganization() {
    return {
      loading: !this.didLoadAdminsForOrganization,
      users: this._adminsForOrganization,
    }
  }

  @computed
  get instructorsForOrganization() {
    return {
      loading: !this.didLoadInstructorsForOrganization,
      users: this._instructorsForOrganization,
    }
  }

  get instructorsForOrganizationByInstructorId() {
    const instructors = new Map<string, OrganizationInstructorDetails>()
    this._instructorsForOrganization.forEach((instructor) => {
      instructors.set(instructor.publicUser.id, instructor)
    })
    return instructors
  }

  get sectionsForOrganization() {
    return this._sectionsForOrganization
  }

  get assignmentsForOrganizationBySectionId() {
    return this._assignmentsForOrganizationBySectionId
  }

  // Make it a map
  get sectionsForOrganizationBySectionId() {
    const sections = new Map<string, Section>()
    this._sectionsForOrganization.forEach((section) => {
      sections.set(section.id, section)
    })
    return sections
  }

  get invoiceRequestsForOrganization() {
    return this.sectionRequestsForOrganization.models.filter(
      (request) =>
        request.data.sectionRequestType === SectionRequestType.invoice
    )
  }

  @computed
  get organizationCatalogIds() {
    return this.organizationCatalogs.models.map(({ id }) => id)
  }

  @computed
  get organizationDefaultCatalogIds() {
    const ids: string[] = []
    this.organizationCatalogs.models.forEach((catalog) => {
      if (catalog.data.defaultCatalogId) {
        ids.push(catalog.id)
      }
    })
    return ids
  }

  approveRequest = async (sectionRequest: SectionRequest) => {
    return updateSectionRequestState(
      this.repository,
      sectionRequest.data.sectionId,
      sectionRequest.id,
      this.repository.currentUser?.uid || '',
      SectionRequestState.approved,
      'approved'
    )
  }

  rejectRequest = async (
    sectionRequest: SectionRequest,
    sectionRequestReason: string
  ) => {
    return updateSectionRequestState(
      this.repository,
      sectionRequest.data.sectionId,
      sectionRequest.id,
      this.repository.currentUser?.uid || '',
      SectionRequestState.rejected,
      sectionRequestReason
    )
  }

  @action
  fetchInstructors() {
    this.didFetchAllInstructors = true
    fetchAllAppUsersByRole(this.repository, UserProfileRole.instructor).then(
      (instructors) => {
        runInAction(() => {
          this.didLoadAllInstructors = true
          this.allInstructors.replace(instructors)
        })
      }
    )
  }

  @action
  setSelectedTab(tab: typeof this.selectedTab) {
    this.selectedTab = tab
  }

  @action
  createOrgInvitation = async (
    args: Omit<Parameters<typeof createOrgInvitation>[1], 'organizationId'>
  ): Promise<string> => {
    return createOrgInvitation(this.repository, {
      ...args,
      organizationId: this.organizationId,
    })
  }

  addInstructor = async (userId: string): Promise<void> => {
    addInstructorToOrganization(this.repository, {
      userId,
      organizationId: this.organizationId,
    })
  }

  addOrganizationalAdmin = async (userId: string): Promise<void> => {
    addOrganizationalAdminToOrganization(this.repository, {
      userId,
      organizationId: this.organizationId,
    })
  }

  removeInstructor = async (instructor: AppUser | string): Promise<void> => {
    removeInstructorFromOrganization(this.repository, {
      userId: typeof instructor === 'string' ? instructor : instructor.uid,
      organizationId: this.organizationId,
    })
  }

  removeOrganizationalAdmin = async (admin: PublicUser): Promise<void> => {
    removeOrganizationalAdminFromOrganization(this.repository, {
      userId: admin.id,
      organizationId: this.organizationId,
    })
  }

  updateCatalogsForUser = async ({
    userId,
    catalogsToRemove,
    catalogsToAdd,
  }: {
    userId: string
    catalogsToRemove: string[]
    catalogsToAdd: string[]
  }) => {
    const addRequests = catalogsToAdd.map((catalogId) =>
      addCatalogToUserProfile(this.repository, {
        catalogId,
        userId,
        organizationId: this.organizationId,
      })
    )
    const removeRequests = catalogsToRemove.map((catalogId) =>
      removeCatalogFromUserProfile(this.repository, { catalogId, userId })
    )

    return await Promise.all([...addRequests, ...removeRequests])
  }

  createOrUpdateOrganizationIntegration = async (
    params: Omit<
      Parameters<typeof createOrUpdateOrganizationIntegration>[1],
      'organizationId'
    >
  ) => {
    return createOrUpdateOrganizationIntegration(this.repository, {
      ...params,
      organizationId: this.organizationId,
    })
  }

  markSectionAsInvoiced(sectionId: string) {
    return setSectionInvoiced(this.repository, { sectionId })
  }
}
