import type { LocalTrack, Room, Track } from 'livekit-client'
import { BroadcastState } from '../../types'
import type { MeetingCubit } from '../MeetingCubit'
import { BroadcastMonitor } from './BroadcastMonitor'
import { TranscriptController } from './TranscriptController'
import { DeviceManager } from '../../util/DeviceManager'
import z from 'zod'
import { captureException } from '@sentry/core'
import { createAudioVolumeAnalyser } from '../../util/audioAnalysis'
import { ServerIdentities } from '../../util/identities'

/**
 * The MeetingLivekitController class is responsible for communicating with Livekit
 * and updating the Meeting model with the relavant data.
 *
 * The idea is to break up the Meeting class into smaller, more manageable pieces.
 */
export class MeetingLivekitController {
  unsubscribers: (() => void)[] = []
  transcriptController: TranscriptController
  isSpeaking = false
  startedSpeakingAt: number | null = null
  durationCapturedSoFar: number = 0
  speechCaptureDebounce: NodeJS.Timeout | null = null
  localTrackAnalyzerCleanup?: () => void = undefined

  constructor(protected meeting: MeetingCubit) {
    this.transcriptController = new TranscriptController(meeting)
  }

  initialize() {
    this.transcriptController.initialize()
    this.setupBroadcastMonitor()
  }

  dispose() {
    for (const unsub of this.unsubscribers) {
      unsub()
    }
    this.livekitRoom?.removeAllListeners()
    this.transcriptController.dispose()
    this.broadcastMonitor?.detach()
    this.localTrackAnalyzerCleanup?.()
  }

  onSlideChange() {
    this.setupBroadcastMonitor()
    this.resetAckFailures()
  }

  lastMessage: string = ''
  sendStreamStatus(
    status: LivekitStreamStatus,
    position: number,
    duration: number
  ) {
    if (!this.livekitRoom) return

    const slideId = this.meeting.currentSlide?.id

    if (!slideId) return

    const message = JSON.stringify({
      type: 6,
      status,
      duration: Math.round(duration),
      position: Math.round(position),
      identity: this.currentUser.uid,
      userId: this.currentUser.uid,
      slideId,
    })

    this.meeting.updateSlideStreamStatus(
      slideId,
      this.currentUser.uid,
      status,
      position,
      duration
    )

    if (this.lastMessage === message) return

    this.lastMessage = message

    const participantArray = Array.from(
      this.livekitRoom.remoteParticipants.values()
    )
    const groupLeaders = participantArray.filter((p) =>
      this.meeting.groupLeaderUserIds.includes(p.identity)
    )

    if (groupLeaders.length === 0) return

    this.livekitRoom.localParticipant?.publishData(
      new TextEncoder().encode(message),
      {
        reliable: true,
        destinationIdentities: groupLeaders.map((p) => p.identity),
      }
    )
  }

  sendBroadcastMessage(message: string) {
    // only internal users and group leaders can send broadcast messages
    const canSend =
      this.currentUser.isInternal || this.meeting.currentUserIsGroupLeader

    if (!canSend) {
      this.meeting.logEvent('meeting_broadcast_message_send_denied', {
        message,
        reason: 'canSendIsFalse',
        isInternal: this.currentUser.isInternal,
        isGroupLeader: this.meeting.currentUserIsGroupLeader,
      })
    }

    if (!this.livekitRoom) {
      this.meeting.logEvent('meeting_broadcast_message_send_denied', {
        message,
        reason: 'livekitRoomMissing',
      })
      return
    }

    if (message === 'pause') {
      this.meeting.setBroadcastState(BroadcastState.paused)
    } else if (message === 'stop') {
      this.meeting.setBroadcastState(BroadcastState.stopped)
    } else if (message === 'play') {
      this.meeting.setBroadcastState(BroadcastState.playing)
    }

    this.addAcknowledgeListener(message)

    this.livekitRoom.localParticipant
      ?.publishData(new TextEncoder().encode(message), {
        reliable: true,
        destinationIdentities: [ServerIdentities.BreakoutLearning],
      })
      .then(() => {
        this.meeting.logEvent('meeting_broadcast_message_sent')
      })
      .catch((e) => {
        const err = e as Error
        this.meeting.logEvent('meeting_broadcast_message_send_error', {
          error: err.name,
          message: err.message,
        })
      })
  }

  sendParticipantMessage(participantUserId: string, message: 'muteMic') {
    // only admins and group leaders can send broadcast messages
    const canSend =
      this.currentUser.isCorre || this.meeting.currentUserAllowedToMute

    if (!canSend) return

    if (!this.livekitRoom) return

    if (message === 'muteMic') {
      const payload = JSON.stringify({
        type: LivekitMessageType.muteMic,
      })
      this.livekitRoom.localParticipant?.publishData(
        new TextEncoder().encode(payload),
        {
          reliable: true,
          destinationIdentities: [participantUserId],
        }
      )
    }
  }

  sendStreamPauseMessage() {
    // only admins and group leaders can send broadcast messages
    const canSend = this.meeting.currentUserIsGroupLeader

    if (!canSend) return

    if (!this.livekitRoom) return

    const allParticipantsIds = Array.from(
      this.livekitRoom.remoteParticipants.values()
    ).map((p) => p.identity)

    const payload = JSON.stringify({
      type: LivekitMessageType.streamPause,
    })
    this.livekitRoom.localParticipant?.publishData(
      new TextEncoder().encode(payload),
      {
        reliable: true,
        destinationIdentities: allParticipantsIds,
      }
    )
  }

  updateMetadata() {
    if (!this.livekitRoom) return
    if (!this.livekitRoom.localParticipant) return
    const currentMetadata = this.livekitRoom.localParticipant.metadata || '{}'
    const parsed = JSON.parse(currentMetadata)
    parsed['info'] = 'test'
    const serialized = JSON.stringify(parsed)
    this.livekitRoom.localParticipant.setMetadata(serialized)
  }

  seekBroadcast(position: number) {
    if (this.livekitRoom) {
      this.meeting.setBroadcastPosition(position + 3)
      this.sendBroadcastMessage(`seek|${position + 3}`)
    }
  }

  private _ackListeners: Record<string, NodeJS.Timeout> = {}
  /**
   * Add an acknowledge listener for a given action
   *
   * It will wait 5 seconds for an ack to be received, and if it isn't
   * it will log an error
   *
   * @param actionString string
   */
  addAcknowledgeListener(actionString: string) {
    // capture which slide we are on when we send the message
    const slide = this.meeting.roomState.data.activeSlide

    // seek messages are special, they are sent with a position
    // so we need to strip that out - we don't care about the position
    // we just care about the action
    const action = actionString.split('|')[0]

    this.meeting.logEvent('meeting_ack_listener', { action })

    this.clearAcknowledgeListener(action)

    this._ackListeners[action] = setTimeout(() => {
      // if we haven't received an ack, we send a Sentry error
      captureException(new Error(`No ack received for ${action}`))
      this.meeting.logEvent('meeting_ack_failure', {
        action,
        slide,
      })
      this.handleAcknowledgeFailure(action)
      delete this._ackListeners[action]
    }, 5000)
  }

  private subsequentAckFailures: Record<string, number> = {}
  /**
   * Handle an acknowledge message from the broadcast
   *
   * @param action string
   */
  handleAcknowledge(action: string) {
    this.subsequentAckFailures[action] = 0

    this.meeting.logEvent('meeting_ack_received', { action })
    this.clearAcknowledgeListener(action)
  }

  handleAcknowledgeFailure(action: string) {
    if (!this.subsequentAckFailures[action])
      this.subsequentAckFailures[action] = 0

    this.subsequentAckFailures[action]++

    const failureCount = this.subsequentAckFailures[action]

    // if play action failed, revert to paused state so someone can try again
    if (action === 'play') {
      this.meeting.setBroadcastState(BroadcastState.paused)
    }

    // if we sent a play/pause message and no ack, we should stop the broadcast, because that
    // means the broadcast is in a bad state, so we'll need to restart it
    if (failureCount >= 3 && (action === 'play' || action === 'pause')) {
      this.meeting.setBroadcastState(BroadcastState.stopped)
    }
  }

  resetAckFailures() {
    this.subsequentAckFailures = {}
  }

  /**
   * Clear the acknowledge listener for a given action
   *
   * This is safe to run even if the listener doesn't exist
   *
   * @param action string
   */
  clearAcknowledgeListener(action: string) {
    if (this._ackListeners[action]) {
      clearTimeout(this._ackListeners[action])
      delete this._ackListeners[action]
    }
  }

  setupDone = false
  setupLivekitListeners() {
    const room = this.livekitRoom
    if (!room) return
    if (this.setupDone) return

    room.on('mediaDevicesChanged', async () => {
      const devices = await DeviceManager.getLocalDevices()

      const activeAudioInput = room.getActiveDevice('audioinput')
      if (activeAudioInput) {
        const audioInputDevices = devices.filter((d) => d.kind === 'audioinput')
        const found = audioInputDevices.find(
          (d) => d.deviceId === activeAudioInput
        )
        if (!found && audioInputDevices.length > 0) {
          room.switchActiveDevice('audioinput', audioInputDevices[0].deviceId)
        }
        if (audioInputDevices.length === 0) {
          this.meeting.repository.logEvent('no_audio_input_devices')
        }
      }

      const activeAudioOutput = room.getActiveDevice('audiooutput')
      if (activeAudioOutput) {
        const audioOutputDevices = devices.filter(
          (d) => d.kind === 'audiooutput'
        )
        const found = audioOutputDevices.find(
          (d) => d.deviceId === activeAudioOutput
        )
        if (!found && audioOutputDevices.length > 0) {
          room.switchActiveDevice('audiooutput', audioOutputDevices[0].deviceId)
        }
        if (audioOutputDevices.length === 0) {
          this.meeting.repository.logEvent('no_audio_output_devices')
        }
      }

      const activeVideoInput = room.getActiveDevice('videoinput')
      if (activeVideoInput) {
        const videoInputDevices = devices.filter((d) => d.kind === 'videoinput')
        const found = videoInputDevices.find(
          (d) => d.deviceId === activeVideoInput
        )
        if (!found && videoInputDevices.length > 0) {
          room.switchActiveDevice('videoinput', videoInputDevices[0].deviceId)
        }
        if (videoInputDevices.length === 0) {
          this.disableVideo()
          this.meeting.repository.logEvent('no_video_output_devices')
        }
      }
    })

    room.on('trackMuted', (publication, participant) => {
      if (participant.identity === this.currentUser.uid) {
        this.meeting.logEvent('room_track_muted', {
          track_kind: publication.kind,
        })
      }
    })
    room.on('trackUnmuted', (publication, participant) => {
      if (participant.identity === this.currentUser.uid) {
        this.meeting.logEvent('room_track_unmuted', {
          track_kind: publication.kind,
        })
      }
    })

    room.on('activeSpeakersChanged', (speakers) => {
      speakers.forEach((speaker) => {
        this.transcriptController.handleSpeakerChange(speaker.identity)
      })
      const isSpeaking = speakers.find(
        (speaker) => speaker.identity === this.currentUser.uid
      )
      if (isSpeaking && !this.isSpeaking) {
        if (this.speechCaptureDebounce) clearTimeout(this.speechCaptureDebounce)
        this.startedSpeakingAt = Date.now()
        this.isSpeaking = true
      } else if (!isSpeaking && this.isSpeaking) {
        if (this.speechCaptureDebounce) clearTimeout(this.speechCaptureDebounce)

        if (this.startedSpeakingAt) {
          // Calculate the duration of the speech - never assume startedSpeakingAt is 0, if it's undefined
          // (which should be impossible)
          const speechDuration = Date.now() - this.startedSpeakingAt

          // Add to the buffer
          this.durationCapturedSoFar =
            this.durationCapturedSoFar + speechDuration

          // give 2 seconds of margin of error - if someone speaks, then pauses and continues
          // we want to treat this as a single block of speech
          this.speechCaptureDebounce = setTimeout(() => {
            // flush the buffer
            this.meeting.logEvent('meeting_user_spoke', {
              duration: this.durationCapturedSoFar,
            })
            // reset the duration, we start over
            this.durationCapturedSoFar = 0
          }, 2000)
        }

        this.startedSpeakingAt = null
        this.isSpeaking = false
      }
    })

    room.on('participantConnected', (p) => {
      this.meeting.addUserConnectionTime(p.identity, Date.now())
      this.updateParticipantIds()
      this.setupBroadcastMonitor()
    })

    room.on('participantDisconnected', (participant) => {
      // unsubscribe from the participant's tracks
      for (const publication of participant.trackPublications.values()) {
        publication.setSubscribed(false)
      }

      this.updateParticipantIds()
      this.setupBroadcastMonitor()
    })
    room.on('trackSubscriptionFailed', async (trackId, participant, error) => {
      const allTrackSubscriptions = Array.from(
        participant.trackPublications.values()
      )
      // capture all track subscriptions for that participant
      const knownTracks = allTrackSubscriptions
        .filter((p) => [p.trackSid, p.kind, p.subscriptionStatus].join(':'))
        .join(', ')

      this.meeting.logEvent('room_track_subscription_failed', {
        trackId,
        participantId: participant.identity,
        connectionStatus: room.state,
        participantPublicationCount: participant.trackPublications.size,
        participantConnectionQuality: participant.connectionQuality,
        userConnectionQuality: room.localParticipant?.connectionQuality,
        subscribedTracks: knownTracks,
        error: error,
      })
    })
    room.on('connectionQualityChanged', (quality, participant) => {
      if (participant.isLocal) {
        this.meeting.logEvent('room_connection_local_quality_changed', {
          quality: quality,
        })
      } else {
        this.meeting.logEvent('room_connection_remote_quality_changed', {
          quality: quality,
          participantId: participant.identity,
        })
      }
    })
    room.on('connectionStateChanged', (state) => {
      this.meeting.logEvent('room_connection_state_changed', {
        state: state,
      })
    })
    room.on('localAudioSilenceDetected', () => {
      this.meeting.logEvent('room_local_audio_silence_detected')
    })
    room.on('trackSubscribed', (track, _, participant) => {
      this.meeting.logEvent('meeting_track_subscribed', {
        trackSid: track.sid,
        kind: track.kind,
        participantId: participant.identity,
      })
    })
    room.on('trackUnsubscribed', (track, _, participant) => {
      this.meeting.logEvent('meeting_track_unsubscribed', {
        trackSid: track.sid,
        kind: track.kind,
        participantId: participant.identity,
      })
    })
    room.on('trackPublished', (publication, participant) => {
      const track = publication.track
      if (track) {
        this.meeting.logEvent('meeting_track_published', {
          trackSid: track.sid,
          kind: track.kind,
          participantId: participant.identity,
        })
      }
    })
    room.on('trackUnpublished', (publication, participant) => {
      const track = publication.track
      if (track) {
        this.meeting.logEvent('meeting_track_unpublished', {
          trackSid: track.sid,
          kind: track.kind,
          participantId: participant.identity,
        })
      }
    })

    room.on('localTrackPublished', async (pub, participant) => {
      const track = pub.track as LocalTrack<Track.Kind.Audio>

      this.meeting.logEvent('meeting_local_track_published', {
        trackSid: track.sid,
        kind: track.kind,
        participantId: participant.identity,
      })

      // we use a string to avoid importing livekit-client package
      if (pub.track?.kind !== 'audio') return

      let localMicSpeechStartedAt: number | null = null
      let isLocalMicSpeaking = false
      let wasMutedOnStart = false

      const debugAudioAnalysis = false

      const cleanup = await createAudioVolumeAnalyser({
        track,
        debug: debugAudioAnalysis,
        onThresholdCross: (thresholdCrossedOver) => {
          if (thresholdCrossedOver && !isLocalMicSpeaking) {
            if (debugAudioAnalysis) {
              // eslint-disable-next-line no-console
              console.log('[audioAnalysis] Speech started')
            }
            // Speech started, set markers
            localMicSpeechStartedAt = Date.now()
            isLocalMicSpeaking = true
            wasMutedOnStart = track.isMuted
          } else if (!thresholdCrossedOver && isLocalMicSpeaking) {
            if (debugAudioAnalysis) {
              // eslint-disable-next-line no-console
              console.log('[audioAnalysis] Speech stopped')
            }
            // Speech ended, log and reset
            if (localMicSpeechStartedAt) {
              const duration = Date.now() - localMicSpeechStartedAt!
              // Log to analytics, include info on mute status
              this.meeting.logEvent('meeting_user_spoke_locally', {
                duration: duration,
                isMuted: track.isMuted,
                wasMutedOnStart,
              })
            }
            wasMutedOnStart = false
            localMicSpeechStartedAt = null
            isLocalMicSpeaking = false
          }

          // is muted logic
          if (track.isMuted) {
            this.meeting.setSpeakingWhileMuted(thresholdCrossedOver)
          }
        },
      })

      const onTrackMute = () => {
        if (isLocalMicSpeaking) this.meeting.setSpeakingWhileMuted(true)
      }
      const onTrackUnmute = () => {
        this.meeting.setSpeakingWhileMuted(false)
      }

      track.on('muted', onTrackMute)
      track.on('unmuted', onTrackUnmute)

      if (this.localTrackAnalyzerCleanup) this.localTrackAnalyzerCleanup()

      this.localTrackAnalyzerCleanup = () => {
        cleanup()
        track.off('muted', onTrackMute)
        track.off('unmuted', onTrackUnmute)
      }
    })

    room.on('localTrackUnpublished', async (pub, participant) => {
      const track = pub.track as LocalTrack<Track.Kind.Audio>

      this.meeting.logEvent('meeting_local_track_unpublished', {
        trackSid: track.sid,
        kind: track.kind,
        participantId: participant.identity,
      })
    })

    room.on('activeSpeakersChanged', (speakers) => {
      const sorted = speakers.sort((a, b) =>
        b.audioLevel > a.audioLevel ? 1 : -1
      )
      const first = sorted[0]

      this.meeting.updateActiveSpeaker(first?.identity || null)
    })
    room.on('dataReceived', (data, sender) => {
      const decoded = new TextDecoder().decode(data)
      const senderIdentity = sender?.identity ?? 'unknown'

      if (decoded) {
        const { data, type } = this.parseLivekitMessage(decoded)
        if (type === LivekitMessageType.status) {
          if (senderIdentity !== ServerIdentities.BreakoutLearning) return

          const { status } = data
          if (status === LivekitStreamStatus.playing)
            this.meeting.setBroadcastState(BroadcastState.playing)
          if (status === LivekitStreamStatus.paused)
            this.meeting.setBroadcastState(BroadcastState.paused)
          if (status === LivekitStreamStatus.stopped)
            this.meeting.setBroadcastState(BroadcastState.stopped)

          this.meeting.logEvent('meeting_lk_message_status', { status })
        } else if (type === LivekitMessageType.duration) {
          if (senderIdentity !== ServerIdentities.BreakoutLearning) return

          this.meeting.logEvent('meeting_lk_message_duration', {
            duration: data.duration,
          })

          const { duration } = data
          this.meeting.setBroadcastDuration(duration)
          // duration
        } else if (type === LivekitMessageType.position) {
          if (senderIdentity !== ServerIdentities.BreakoutLearning) return
          // if we receive a position message then we must be playing
          // the user missed the play message
          if (
            this.meeting.broadcastState === BroadcastState.uninitialized ||
            this.meeting.broadcastState === BroadcastState.initialized
          ) {
            this.meeting.setBroadcastState(BroadcastState.playing)
          }

          // We DO NOT send a logEvent here by design - it's too noisy

          // if we got duration/position on a non-video slide, we should stop the broadcast
          if (!this.meeting.currentSlide?.isVideoSlide) {
            console.error(
              'Broadcast detected on non-video slide, stopping broadcast'
            )
            this.sendBroadcastMessage('stop')
          }
          const { position, duration } = data
          this.meeting.setBroadcastPositionAndDuration(position, duration)
        } else if (type === LivekitMessageType.transcript) {
          // only accept transcript messages from the Secretary or the Moderator
          if (
            senderIdentity !== ServerIdentities.Secretary &&
            senderIdentity !== ServerIdentities.Moderator
          ) {
            return
          }

          this.transcriptController.handleMessage(data)
        } else if (type === LivekitMessageType.unknown) {
          // should not happen
          captureException(new Error('Received unknown livekit message type'))
        } else if (type === 6) {
          const { status, position, duration, userId, slideId } = data
          if (!duration) return

          this.meeting.updateSlideStreamStatus(
            slideId,
            userId,
            status,
            position,
            duration
          )
        } else if (type === LivekitMessageType.streamPause) {
          this.meeting.logEvent('meeting_lk_message_pause')
          this.meeting.localCommands.emit('pauseVideo')
        } else if (type === LivekitMessageType.muteMic) {
          this.meeting.logEvent('meeting_lk_message_mutemic')
          this.mute()
        } else if (type === LivekitMessageType.acknowledge) {
          if (senderIdentity !== ServerIdentities.BreakoutLearning) return

          this.handleAcknowledge(data.action)
        }
      }
    })
    room.on('disconnected', () => {
      this.meeting.logEvent('room_disconnected')
      this.removeIsSpeakingListener()
    })
    room.on('reconnected', () => {
      this.meeting.logEvent('room_reconnected')
    })
    room.on('connected', () => {
      this.meeting.logEvent('room_connected')
      this.updateParticipantIds()
      this.setupBroadcastMonitor()
      this.addIsSpeakingListener()

      const participants = Array.from(room.remoteParticipants.values())

      const breakoutParticipant = participants.find(
        (p) => p.identity === ServerIdentities.BreakoutLearning
      )

      if (breakoutParticipant) {
        // assume we are initialized, an event from the broadcast will update this
        this.meeting.setBroadcastState(BroadcastState.initialized)
      }
    })
  }

  addIsSpeakingListener() {
    this.livekitRoom?.localParticipant.addListener(
      'isSpeakingChanged',
      this.handleIsSpeakingChanged
    )
  }

  removeIsSpeakingListener() {
    this.livekitRoom?.localParticipant.removeListener(
      'isSpeakingChanged',

      this.handleIsSpeakingChanged
    )
  }

  lastSpeechStart: number | null = null
  handleIsSpeakingChanged = (speaking: boolean) => {
    if (speaking && !this.lastSpeechStart) {
      this.lastSpeechStart = Date.now()
    }
    if (!speaking && this.lastSpeechStart) {
      const durationMs = Date.now() - this.lastSpeechStart
      this.transcriptController.addSpeechDuration(durationMs)
      this.lastSpeechStart = null
    }
  }

  updateParticipantIds() {
    if (!this.livekitRoom) return
    const participants = Array.from(
      this.livekitRoom.remoteParticipants.values()
    )
    const userIds = participants.map((p) => p.identity)

    this.meeting.updateParticipantIds(userIds)
  }

  mute() {
    if (!this.livekitRoom) return Promise.resolve(undefined)
    return this.livekitRoom?.localParticipant.setMicrophoneEnabled(false)
  }

  get isMuted() {
    return this.livekitRoom?.localParticipant.isMicrophoneEnabled === false
  }

  toggleAudio() {
    if (!this.livekitRoom) return Promise.resolve(undefined)
    const currentState = this.livekitRoom.localParticipant.isMicrophoneEnabled
    return this.livekitRoom?.localParticipant.setMicrophoneEnabled(
      !currentState
    )
  }

  unmute() {
    if (!this.livekitRoom) return Promise.resolve(undefined)
    return this.livekitRoom?.localParticipant.setMicrophoneEnabled(true)
  }

  isAudioEnabled() {
    return this.livekitRoom?.localParticipant.isMicrophoneEnabled
  }

  toggleVideo() {
    if (!this.livekitRoom) return Promise.resolve(undefined)
    const currentState = this.livekitRoom.localParticipant.isCameraEnabled
    return this.livekitRoom?.localParticipant.setCameraEnabled(!currentState)
  }

  toggleScreenShare() {
    if (!this.livekitRoom) return Promise.resolve(undefined)
    const currentState = this.livekitRoom.localParticipant.isScreenShareEnabled
    return this.livekitRoom?.localParticipant.setScreenShareEnabled(
      !currentState
    )
  }

  stopScreenShare() {
    if (!this.livekitRoom) return Promise.resolve(undefined)
    return this.livekitRoom?.localParticipant.setScreenShareEnabled(false)
  }

  disableVideo() {
    if (!this.livekitRoom) return Promise.resolve(undefined)
    return this.livekitRoom?.localParticipant.setCameraEnabled(false)
  }

  enableVideo() {
    if (!this.livekitRoom) return Promise.resolve(undefined)
    return this.livekitRoom?.localParticipant.setCameraEnabled(true)
  }

  isVideoEnabled() {
    return this.livekitRoom?.localParticipant.isCameraEnabled
  }

  toggleVideoForParticipant(participantId: string) {
    const participant = this.livekitRoom?.remoteParticipants.get(participantId)
    if (!participant) return

    for (const publication of participant.trackPublications.values()) {
      if (publication.kind.toString() === 'video') {
        publication.setSubscribed(!publication.isSubscribed)
      }
    }
  }

  get livekitRoom(): Room | null {
    return this.meeting.livekitRoom
  }

  get currentUser() {
    return this.meeting.currentUser
  }

  broadcastMonitor: BroadcastMonitor | null = null

  setupBroadcastMonitor() {
    if (!this.livekitRoom) {
      return
    }
    const participants = Array.from(
      this.livekitRoom.remoteParticipants.values()
    )

    const breakoutParticipant = participants.find(
      (p) => p.identity === ServerIdentities.BreakoutLearning
    )

    // if the slide changed and we have a running broadcastMonitor,
    // we need to flush it and start a new one
    if (
      breakoutParticipant &&
      this.broadcastMonitor &&
      this.broadcastMonitor.monitoredSlide !== this.meeting.activeSlide
    ) {
      this.broadcastMonitor.flush()
      this.broadcastMonitor.detach()
      this.broadcastMonitor = new BroadcastMonitor(this.meeting)
      this.broadcastMonitor.attach()
    }
    if (breakoutParticipant && !this.broadcastMonitor) {
      this.broadcastMonitor = new BroadcastMonitor(this.meeting)
      this.broadcastMonitor.attach()
    } else if (!breakoutParticipant && this.broadcastMonitor) {
      this.broadcastMonitor.flush()
      this.broadcastMonitor.detach()
      this.broadcastMonitor = null
    }
  }

  private parseLivekitMessage(message: string) {
    const json = JSON.parse(message)
    const base = baseMessageSchema.parse(json)

    // i know it's redundant to return the type in the data prop as well as
    // the base.type, but it's the only way I could get the type inference
    // to work in the ifs/switches above
    switch (base.type) {
      case LivekitMessageType.status:
        return { data: statusMessageSchema.parse(json), type: base.type }
      case LivekitMessageType.duration:
        return { data: durationMessageSchema.parse(json), type: base.type }
      case LivekitMessageType.position:
        return { data: positionMessageSchema.parse(json), type: base.type }
      case LivekitMessageType.transcript:
        return { data: transcriptMessageSchema.parse(json), type: base.type }
      case LivekitMessageType.streamPosition:
        return {
          data: streamPositionMessageSchema.parse(json),
          type: base.type,
        }
      case LivekitMessageType.acknowledge:
        return { data: acknowledgeMessageSchema.parse(json), type: base.type }
    }
    return { data: undefined, type: base.type }
  }
}

enum LivekitMessageType {
  status = 0,
  duration = 1,
  position = 2,
  transcript = 3,
  unknown = 4,
  resubscribe = 5,
  streamPosition = 6,
  streamPause = 7,
  muteMic = 8,
  acknowledge = 9,
}

export enum LivekitStreamStatus {
  uninitialized = 0,
  initialized = 1,
  playing = 2,
  paused = 3,
  stopped = 4,
}

const baseMessageSchema = z.object({
  type: z.nativeEnum(LivekitMessageType),
})

const statusMessageSchema = baseMessageSchema.extend({
  type: z.literal(LivekitMessageType.status),
  status: z.nativeEnum(LivekitStreamStatus),
})

const durationMessageSchema = baseMessageSchema.extend({
  type: z.literal(LivekitMessageType.duration),
  duration: z.number().int(),
})

const positionMessageSchema = baseMessageSchema.extend({
  type: z.literal(LivekitMessageType.position),
  position: z.number().int(),
  duration: z.number().int(),
})

const transcriptMessageSchema = baseMessageSchema.extend({
  type: z.literal(LivekitMessageType.transcript),
  transcript: z.string(),
  identity: z.string(),
  id: z.number().int(), // transcriptId
  final: z.boolean(),
})

const streamPositionMessageSchema = baseMessageSchema.extend({
  type: z.literal(LivekitMessageType.streamPosition),
  position: z.number().int(),
  duration: z.number().int().nullish(),
  slideId: z.string(),
  userId: z.string(),
  status: z.nativeEnum(LivekitStreamStatus),
})

const acknowledgeMessageSchema = baseMessageSchema.extend({
  type: z.literal(LivekitMessageType.acknowledge),
  action: z.string(),
})
