import { type FirebaseRepository } from '../../models/FirebaseRepository'
import { ChatController } from '../meeting/ChatController'
import {
  getRoomMessages,
  markMessageAsSeen,
  sendMessage,
} from '../../firestore/RoomMessage'
import { FirestoreRoomMessageType, type ChatMessage } from '../../types'
import { captureException } from '@sentry/core'

export class StudentAssignmentChatController extends ChatController {
  constructor({
    repository,
    roomId,
  }: {
    repository: FirebaseRepository
    roomId: string
  }) {
    super({ repository, roomId })
  }

  // this init can fail if the student is not yet in the room
  // so we retry a few times before bubbling up the error
  initialize(retryCount = 0): void {
    this.addStream(
      getRoomMessages(this.repository, {
        roomId: this.roomId,
        scheduling: true,
      }),
      (messages) => {
        this.messagesCol.replaceModels(messages)
      },
      {
        disableCaptureException: true,
        onError: (error) => {
          if (retryCount < 5) {
            setTimeout(() => {
              // if we've been disposed, don't attempt to start stream
              if (this.isDisposed) return
              this.initialize(retryCount + 1)
            }, 400 * retryCount)
          } else {
            console.error(error)
            captureException(error)
          }
        },
      }
    )
  }

  get unreadMessageCount() {
    let count = 0
    for (const message of this.messagesCol.models) {
      // don't count bot messages
      if (message.data.type === FirestoreRoomMessageType.SchedulingBot) continue
      if (!this.readMessages.get(message.id)) {
        count++
      }
    }
    return count
  }

  get hasUnreadMessages() {
    for (const message of this.messagesCol.models) {
      // don't count bot messages
      if (message.data.type === FirestoreRoomMessageType.SchedulingBot) continue
      if (!this.readMessages.get(message.id)) {
        return true
      }
    }
    return false
  }

  localStorageKey() {
    return `chat-${this.roomId}-readMessages-scheduling`
  }

  async markSeen(message: ChatMessage) {
    // don't mark read if we authored the message
    if (
      message.authorId === this.currentUser.uid ||
      this.currentUser.uid in (message.seenBy ?? {})
    ) {
      return
    }
    await markMessageAsSeen(this.repository, this.roomId, message.id)
  }

  sendMessage = async (text: string) => {
    sendMessage(this.firestore, this.roomId, this.currentUser.uid, text, {
      scheduling: true,
    })
  }

  formatDate(date: Date) {
    return date.toLocaleTimeString(undefined, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
  }
}
