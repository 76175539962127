import { action, computed, makeObservable, observable } from 'mobx'
import type { StreamSubscriptionActions } from 'tricklejs/dist/stream_subscription'
import { getPublicUsers } from '../firestore/PublicUser'
import { PublicUser } from '../models/PublicUser'
import type { FirebaseRepository } from '../models/FirebaseRepository'

export class UserStore {
  repository: FirebaseRepository
  @observable private store = observable.array<PublicUser>([], { deep: false })
  timeout: NodeJS.Timeout | null = null
  subscription?: StreamSubscriptionActions

  constructor(repository: FirebaseRepository) {
    this.repository = repository
    makeObservable(this)
  }

  scheduleRecompute() {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.recompute()
    }, 100)
  }

  recompute() {
    if (this.subscription) {
      this.subscription.cancel()
      this.subscription = undefined
    }

    if (!this.repository.isAuthenticated) return

    const userIds = this.store
      .map((user) => user.id)
      // real empty user have id === '', we skip them
      .filter((id) => id !== '')
      .sort()

    // if there are no users, don't bother
    if (userIds.length === 0) return

    const stream = getPublicUsers(this.repository, { userIds })

    this.subscription = stream.listen((models) => {
      this.updateStore(models)
    })
  }

  @action
  updateStore(models: PublicUser[]) {
    for (const model of models) {
      const found = this.byId[model.id]
      if (found) {
        found.replaceDoc(model.doc)
      } else {
        model.changeLoadingState(false)
        this.store.push(model)
      }
    }
  }

  @action
  getUsers(userIds: string[]) {
    const array = []
    for (const userId of userIds) {
      const found = this.byId[userId]
      if (found) {
        array.push(found)
      } else {
        const empty = PublicUser.empty(this.repository, userId)
        this.store.push(empty)
        this.scheduleRecompute()
        array.push(empty)
      }
    }
    return array
  }

  @action
  getUser(userId: string) {
    const found = this.byId[userId]
    if (found) {
      return found
    } else {
      const empty = PublicUser.empty(this.repository, userId)
      this.store.push(empty)
      this.scheduleRecompute()
      return empty
    }
  }

  @action
  addUser(user: PublicUser) {
    this.store.push(user)
  }

  @computed
  get byId() {
    const map: Record<string, PublicUser> = {}
    for (const user of this.store) {
      map[user.id] = user
    }
    return map
  }

  clear() {
    if (this.subscription) {
      this.subscription.cancel()
      this.subscription = undefined
    }
    this.store.clear()
  }
}
