import z from 'zod'
import {
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalString,
} from '../shared/schema'

export enum UserProfileNotificationType {
  Custom = 0,
  GroupChat = 1,
}

export const schema = z.object({
  notificationContextKey: z.record(z.string()),
  notificationSourceUserId: z.string(),
  notificationSubject: z.string(),
  notificationHTMLBody: nullSafeOptionalString(),
  notificationTextBody: nullSafeOptionalString(),
  notificationSent: z.boolean(),
  notificationSeen: z.boolean(),
  notificationType: z.preprocess(
    (val) => val ?? UserProfileNotificationType.Custom,
    z.nativeEnum(UserProfileNotificationType)
  ),
  createdAt: nullSafeOptionalFirestoreTimestamp(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    notificationContextKey: {},
    notificationSourceUserId: '',
    notificationSubject: '',
    notificationSent: false,
    notificationSeen: false,
    notificationType: UserProfileNotificationType.Custom,
    createdAt: { seconds: 0, nanoseconds: 0 },
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export interface FirestoreUserProfileNotification
  extends z.infer<typeof schema> {}
