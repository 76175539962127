import type { ObservableMap } from 'mobx'
import { observable } from 'mobx'
import { BroadcastState, type TranscriptMessage } from '../../types'
import type { MeetingCubit } from '../MeetingCubit'
import { SlideType } from '../../firestore/Slide/types'

export class SlideTimerController {
  meeting: MeetingCubit
  _messages: ObservableMap<number, TranscriptMessage> = observable.map({})

  slideTimer: NodeJS.Timeout | null = null
  videoDidNotStartTimer: NodeJS.Timeout | null = null
  debugMode = false

  constructor(meeting: MeetingCubit, options: { debug?: boolean } = {}) {
    this.meeting = meeting
    this.debugMode = options.debug || false
  }

  dispose() {
    this.cancelSlideTimer()
    this.cancelVideoDidNotStartTimer()
  }

  cancelSlideTimer() {
    this.debugLog('cancelSlideTimer')
    if (this.slideTimer) {
      clearInterval(this.slideTimer)
    }
  }

  cancelVideoDidNotStartTimer() {
    this.debugLog('cancelVideoDidNotStartTimer')
    if (this.videoDidNotStartTimer) {
      clearTimeout(this.videoDidNotStartTimer)
    }
  }

  resetSlideTimer() {
    this.debugLog('resetSlideTimer')
    this.meeting.updateProgressBar(0)
    this.cancelSlideTimer()
    this.cancelVideoDidNotStartTimer()

    if (
      this.meeting.currentSlide?.type !== SlideType.video &&
      this.meeting.currentSlide?.duration === 0
    ) {
      this.meeting.updateProgressBar(1)
    } else {
      this.calculateSlideTimer()
      this.slideTimer = setInterval(() => {
        this.calculateSlideTimer()
      }, 1000)
    }

    this.startVideoDidNotStartTimer()
  }

  startVideoDidNotStartTimer() {
    const isVideo = this.meeting.currentSlide?.type === SlideType.video
    const isBroadcasting = !this.meeting.streamingMode

    if (isVideo && isBroadcasting) {
      this.videoDidNotStartTimer = setTimeout(() => {
        if (this.meeting.breakoutParticipantIsPresent) {
          if (this.meeting.broadcastPosition === 0) {
            this.meeting.updateProgressBar(1)
            this.meeting.sendBroadcastMessage('play')
          }
        } else {
          this.meeting.updateProgressBar(1)
          this.meeting.setBroadcastState(BroadcastState.stopped)
        }
      }, 8000)
    }
  }

  calculateSlideTimer() {
    this.debugLog('calculateSlideTimer')
    const currentSlide = this.meeting.currentSlide
    const slideControlsProgress = currentSlide?.type === SlideType.video

    if (slideControlsProgress) return

    const slideDuration = currentSlide?.duration
    const slideChangedAt = this.meeting.activeSlideChangedAt
    if (!slideChangedAt || !slideDuration) {
      this.debugLog('slideChangedAt or slideDuration is not set', {
        slideChangedAt,
        slideDuration,
      })
      return
    }

    const elapsedSeconds = (Date.now() - slideChangedAt.getTime()) / 1000

    const currentProgress = elapsedSeconds / slideDuration

    const timeDifference = elapsedSeconds % 5

    /// If elapsed time is less than slide duration + 1 minute
    /// or there is an integer of 5 seconds since the last time
    /// we updated the progress bar
    if (elapsedSeconds < slideDuration || timeDifference === 0) {
      this.meeting.updateProgressBar(
        currentProgress === 0 ? 0.0001 : currentProgress
      )
    } else {
      this.debugLog('skipping progress update')
    }

    if (elapsedSeconds >= slideDuration) {
      this.endTimer()
    }

    this.debugLog({
      slideDuration,
      elapsedSeconds,
      timeDifference,
    })
  }

  endTimer() {
    this.cancelSlideTimer()
    this.cancelVideoDidNotStartTimer()
    this.meeting.updateProgressBar(1)
  }

  debugLog(...args: unknown[]) {
    if (this.debugMode) {
      // eslint-disable-next-line no-console
      console.log(...args)
    }
  }
}
