import type { UserProfileNotification } from '../models/UserProfileNotification'
import { UserProfileNotificationType } from '../types/index'

function computeGroupingKeyForGroupChat(notification: UserProfileNotification) {
  const context = notification.dataToJs.notificationContextKey

  return `group-chat-${context.roomId}-${context.sectionId}-${context.slideDeckId}`
}

export function computeGroupingKey(
  notification: UserProfileNotification
): string | undefined {
  if (!notification.isLoaded) return undefined
  const type = notification.data.notificationType

  switch (type) {
    case UserProfileNotificationType.GroupChat:
      return computeGroupingKeyForGroupChat(notification)
    case UserProfileNotificationType.Custom:
      return notification.id
  }
}

export type NotificationGroup = {
  subject: string
  userId: string
  userIds: string[]
  type: UserProfileNotificationType
  groupingKey: string
  timestamp: number
  notifications: UserProfileNotification[]
  context: UserProfileNotification['data']['notificationContextKey']
}

export function groupNotifications(
  notifications: UserProfileNotification[]
): NotificationGroup[] {
  const sorted = notifications
    .slice()
    .sort(
      (a, b) =>
        (a.data.createdAt?.getTime() || 0) - (b.data.createdAt?.getTime() || 0)
    )

  const groupedByKey: Record<string, NotificationGroup> = {}

  for (const notification of sorted) {
    const key = computeGroupingKey(notification)
    if (!key) continue // skip notifications that can't be grouped
    const group = groupedByKey[key]
    const timestamp = notification.data.createdAt
      ? notification.data.createdAt.getTime()
      : 0
    const sourceUserId = notification.data.notificationSourceUserId
    if (!group) {
      groupedByKey[key] = {
        subject: notification.data.notificationSubject || '',
        type: notification.data.notificationType,
        context: notification.data.notificationContextKey || {},
        groupingKey: key,
        userId: sourceUserId,
        userIds: [sourceUserId],
        timestamp: timestamp,
        notifications: [notification],
      }
    } else {
      if (group.timestamp < timestamp) {
        // new notification is more recent than existing one, bump the timestamp
        group.timestamp = timestamp
      }
      group.notifications.push(notification)
      if (!group.userIds.includes(sourceUserId)) {
        group.userIds.push(sourceUserId)
      }
    }
  }

  return Object.values(groupedByKey).sort((a, b) => a.timestamp - b.timestamp)
}
