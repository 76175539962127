import z from 'zod'
import { nullSafeOptionalBoolean } from '../shared/schema'

export const schema = z.object({
  roomInspector: nullSafeOptionalBoolean(false),
  canViewFeatureFlags: nullSafeOptionalBoolean(false),
  canInvokeEmailTemplateHandler: nullSafeOptionalBoolean(false),
})

export const empty = Object.freeze(
  schema.parse({
    roomInspector: false,
    canViewFeatureFlags: false,
    canInvokeEmailTemplateHandler: false,
  })
)

export type FirestoreSettingsSuperAdmin = z.infer<typeof schema>
